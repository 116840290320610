import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  passwordChangeForm: FormGroup;
  userDataForm: FormGroup;

  errorPasswordChange: string;
  messagePasswordChange: string;
  errorUserData: string;
  messageUserData: string;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    public authService: AuthService
  ) {
    this.passwordChangeForm = this.formBuilder.group({
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: 'blur',
        },
      ],
      passwordRe: [
        '',
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: 'blur',
        },
      ],
    });

    this.userDataForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        },
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: [''],
    });
  }

  ngOnInit(): void {
    this.apiCallService
      .apiGet<ApiResponse>('/api/logged-in-user')
      .subscribe((result) => {
        if (result.isSuccess()) {
          this.userDataForm.get('email').setValue(result.data['email']);
          this.userDataForm
            .get('firstName')
            .setValue(result.data['first_name']);
          this.userDataForm.get('lastName').setValue(result.data['last_name']);
          this.userDataForm
            .get('mobile')
            .setValue(result.data['mobile_number']);
        }
      });
  }

  hasErrorPasswordChange(input: string): boolean {
    return (
      this.passwordChangeForm.controls[input].invalid &&
      (this.passwordChangeForm.controls[input].dirty ||
        this.passwordChangeForm.controls[input].touched)
    );
  }

  hasErrorUserData(input: string): boolean {
    return (
      this.userDataForm.controls[input].invalid &&
      (this.userDataForm.controls[input].dirty ||
        this.userDataForm.controls[input].touched)
    );
  }

  checkConfirm() {
    const pwd = this.passwordChangeForm.get('password').value;
    const confirm = this.passwordChangeForm.get('passwordRe').value;
    if (pwd.length > 0 && confirm.length > 0 && confirm != pwd) {
      this.passwordChangeForm.get('passwordRe').setErrors({ mismatch: true });
    }
  }

  passwordChange() {
    this.errorUserData = null;
    this.messageUserData = null;
    if (this.passwordChangeForm.invalid) {
      let hasEmpty = false;
      Object.keys(this.passwordChangeForm.controls).forEach((key) => {
        this.passwordChangeForm.get(key).markAsDirty();
        if (this.passwordChangeForm.get(key).value.length < 1) {
          hasEmpty = true;
        }
      });
      if (hasEmpty) {
        this.errorPasswordChange = this.chs.getText('kotelezo_mezok', true);
      }
      return;
    }

    const formdata = new FormData();
    formdata.append('password', this.passwordChangeForm.get('password').value);
    formdata.append(
      'password_confirmation',
      this.passwordChangeForm.get('passwordRe').value
    );
    this.apiCallService
      .apiPut<ApiResponse>('/api/logged-in-user/password', formdata)
      .subscribe((result) => {
        if (result.isSuccess()) {
          this.messagePasswordChange = this.chs.getText(
            'sikeres_adatmodositas'
          );
          this.errorPasswordChange = null;
        } else {
          this.messagePasswordChange = null;
          this.errorPasswordChange = this.chs.handleError(
            this.passwordChangeForm,
            result
          );
        }
      });
  }

  changeUserData() {
    this.errorPasswordChange = null;
    this.messagePasswordChange = null;
    if (this.userDataForm.invalid) {
      let hasEmpty = false;
      Object.keys(this.userDataForm.controls).forEach((key) => {
        this.userDataForm.get(key).markAsDirty();
        if (this.userDataForm.get(key).value.length < 1) {
          hasEmpty = true;
        }
      });
      if (hasEmpty) {
        this.errorUserData = this.chs.getText('kotelezo_mezok', true);
      }
      return;
    }

    const formdata = new FormData();
    formdata.append('email', this.userDataForm.get('email').value);
    this.apiCallService
      .apiPut<ApiResponse>('/api/logged-in-user/email', formdata)
      .subscribe((result) => {
        if (result.isSuccess()) {
          const innerFormData = new FormData();
          innerFormData.append(
            'first_name',
            this.userDataForm.get('firstName').value
          );
          innerFormData.append(
            'last_name',
            this.userDataForm.get('lastName').value
          );
          innerFormData.append(
            'mobile_number',
            this.userDataForm.get('mobile').value
          );

          this.apiCallService
            .apiPut<ApiResponse>('/api/logged-in-user', innerFormData)
            .subscribe((result) => {
              if (result.isSuccess()) {
                this.messageUserData = this.chs.getText(
                  'sikeres_adatmodositas'
                );
                this.errorUserData = null;
              } else {
                this.messageUserData = null;
                this.errorUserData = this.chs.handleError(
                  this.userDataForm,
                  result
                );
              }
            });
        } else {
          this.messageUserData = null;
          this.errorUserData = this.chs.handleError(this.userDataForm, result);
        }
      });
  }

  logout() {
    this.authService.logout().subscribe();
  }
}
