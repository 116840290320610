import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss'],
})
export class FirmwareComponent {
  public serial: string = 'A';
  public versionNum: string;
  public versions: any[] = [];
  private _file: File = null;

  @ViewChild('inputFile') myInputVariable: ElementRef;

  constructor(public httpClient: HttpClient, public chs: CommonHandlerService) {
    this.getVersions();
  }

  getVersions() {
    this.httpClient
      .get(environment.apiUrl + '/api/firmware')
      .subscribe((response) => {
        this.versions = (response as any)['data'];
      });
  }

  getLastVersionOfSerial() {
    let arr = this.versions.filter(
      (version) => version['series'] == this.serial
    );
    return arr.length > 0 ? this.getVersion(arr[arr.length - 1]) : 'N/A';
  }

  getVersion(elem: any) {
    return elem.series + String(elem.version).padStart(3, '0');
  }

  handleFileInput(fileList: FileList) {
    this._file = fileList.item(0);
    /*let file = fileList.item(0);
    let formData:FormData = new FormData();
    formData.append('series', this.serial);
    formData.append('file', file, file.name);

    this.httpClient.post(environment.apiUrl+'/api/firmware', formData).subscribe(response => {
      this.myInputVariable.nativeElement.value="";
      if((response as ApiResponse).isSuccess()) {
        alert('Sikeres feltöltés');
        this.getVersions();
      } else {
        alert('Sikertelen feltöltés');
      }
    });*/
  }

  upload() {
    if (this.versionNum == null || this.versionNum.length == 0) {
      alert('Hiányzik a verziószám!');
    } else if (this._file == null) {
      alert('Hiányzik a fájl!');
    } else {
      let formData: FormData = new FormData();
      formData.append('series', this.serial);
      formData.append('file', this._file, this._file.name);
      formData.append('version', this.versionNum.padStart(3, '0'));

      this.httpClient
        .post(environment.apiUrl + '/api/firmware', formData)
        .subscribe((response) => {
          this.myInputVariable.nativeElement.value = '';
          this._file = null;
          this.versionNum = null;
          if ((response as ApiResponse).isSuccess()) {
            alert('Sikeres feltöltés');
            this.getVersions();
          } else {
            alert('Sikertelen feltöltés');
          }
        });
    }
  }
}
