import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { ApiResponseInterceptor } from 'src/app/core/services/api-call-service/api-response.interceptor';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  submit: boolean;
  error: string = null;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.registrationForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        },
      ],
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: 'blur',
        },
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: [''],
    });
  }

  ngOnInit(): void {}

  register() {
    this.submit = true;
    if (this.registrationForm.invalid) {
      let hasEmpty = false;
      Object.keys(this.registrationForm.controls).forEach((key) => {
        this.registrationForm.get(key).markAsDirty();
        if (this.registrationForm.get(key).value.length < 1) {
          hasEmpty = true;
        }
      });
      if (hasEmpty) {
        this.error = this.chs.getText('kotelezo_mezok', true);
      }
      return;
    }

    const formdata = new FormData();
    formdata.append('email', this.registrationForm.get('email').value);
    formdata.append('password', this.registrationForm.get('password').value);
    formdata.append(
      'password_confirmation',
      this.registrationForm.get('password').value,
    );
    formdata.append('last_name', this.registrationForm.get('lastName').value);
    formdata.append('first_name', this.registrationForm.get('firstName').value);
    formdata.append('mobile_number', this.registrationForm.get('mobile').value);
    this.apiCallService
      .apiPost<ApiResponse>('/api/users', formdata)
      .subscribe((result) => {
        if (result.isSuccess()) {
          this.router.navigate(['success'], {
            state: { data: 'registration' },
          });
        } else {
          this.error = this.chs.handleError(this.registrationForm, result);
        }
      });
  }

  hasError(input: string): boolean {
    return (
      this.registrationForm.controls[input].invalid &&
      (this.registrationForm.controls[input].dirty ||
        this.registrationForm.controls[input].touched)
    );
  }
}
