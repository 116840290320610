import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const requiresLogin = route.data.requiresLogin || false;
    const requiredRole = route.data.role || null;
    if (!!requiresLogin) {
      if (!this.authService.isLoggedIn) {
        return this.router.parseUrl('/login');
      }

      if (!!requiredRole && !this.authService.canAccess(requiredRole)) {
        return this.router.parseUrl('/home');
      }

      return true;
    }

    return true;
  }
}
