<div class="page-wrapper">
  <a class="admin-home-link" routerLink="/admin/home">&lt; HOME</a>

  <h1>{{ chs.getText("admin_tablazat_fejlec") }}</h1>
  <input
    type="text"
    style="padding: 8px; margin: 15px auto; width: 30%"
    placeholder="{{ chs.getText('admin_tablazat_tabla_szuro') }}"
    [(ngModel)]="filter"
  />
  <ngx-datatable
    class="material fullscreen"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [scrollbarH]="true"
    [scrollbarV]="false"
    [rows]="filteredControllers"
    [limit]="25"
  >
    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_utolso_uzenet')"
      prop="lastActivity"
      [width]="80"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span [ngClass]="{ inactive: !checkStatus(row) }">{{
          value | date : "medium"
        }}</span>
      </ng-template></ngx-datatable-column
    >

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_be_ki')"
      prop="onoff"
      [width]="100"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value === 1" class="on">ON</span>
        <span *ngIf="value === 0" class="off">OFF</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="ID" prop="mac">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <!--<a [routerLink]="'/admin/direct-message/' + row.mac">{{
          getMac(value)
        }}</a>-->
        <a [routerLink]="'/admin/control/' + row.id" target="_blank">{{
          getMac(value)
        }}</a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_nev')"
      prop="name"
    ></ngx-datatable-column>
    <ngx-datatable-column
      *ngIf="this.authService.canAccess(ROLE.SUPERUSER)"
      name="Customer"
      prop="customer_id"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="'/admin/customers/' + row.customer_id">{{
          getCustomer(value)
        }}</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_nx_verzio')"
      prop="NXversion"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_sw_verzio')"
      prop="SWversion"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_homerseklet')"
      prop="setTemperature"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ getTemp(row, "temperature") }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_vissza_homerseklet')"
      prop="setTemperature"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ getTemp(row, "returnC") }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_ventillator')"
      prop="fanSpeed"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <a [href]="'/admin/fan-settings/' + row.id" target="_blank"
          >{{ value }} / {{ row.fanSteps }}</a
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_tabla_mod')"
      prop="mode"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <img
          class="abs"
          src="assets/icons/fan.svg"
          [ngClass]="{
            none: value != undefined && value != null
          }"
        />
        <img
          class="abs"
          src="assets/icons/snowflake_mode_new.svg"
          [ngClass]="{ none: value != 1 }"
        />
        <img
          class="abs"
          src="assets/icons/fan.svg"
          [ngClass]="{ none: value != 3 }"
        />
        <img
          class="abs"
          src="assets/icons/sun_mode_new.svg"
          [ngClass]="{ none: value != 4 }"
        />
        <img
          class="abs"
          src="assets/icons/auto_mode.svg"
          [ngClass]="{ none: value != 5 }"
        />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_eco_mod')"
      prop="uzemmod"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <img class="abs" src="assets/icons/leaf_blue.png" *ngIf="value == 0" />
        <img class="abs" src="assets/icons/leaf_grn.png" *ngIf="value == 1" />
        <img class="abs" src="assets/icons/rocket.png" *ngIf="value == 2" />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_cvp')"
      prop="fanSpeed"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <img
          [ngClass]="{ none: !row.comp }"
          src="assets/icons/compressor_on.svg"
        />
        <img
          [ngClass]="{ none: row.comp }"
          src="assets/icons/compressor_off.svg"
        />

        <img [ngClass]="{ none: !row.pump }" src="assets/icons/pump_on.svg" />
        <img [ngClass]="{ none: row.pump }" src="assets/icons/pump_off.svg" />

        <img [ngClass]="{ none: !row.valve }" src="assets/icons/valve_on.svg" />
        <img [ngClass]="{ none: row.valve }" src="assets/icons/valve_off.svg" />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_evap')"
      prop="setTemperature"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ getTemp(row, "evapC") }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_set_homerseklet')"
      prop="setTemperature"
      [width]="100"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ getTemp(row, "coolingC") }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_curr')"
      prop="currentA"
      [width]="100"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }} A
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="chs.getText('admin_tablazat_hibak')"
      prop="error_0"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <ul>
          <li *ngFor="let error of getErrors(row)">
            {{ error }}
          </li>
        </ul>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
