<div class="page-wrapper margin-top-50">
  <h1 class="upper text-center mb-5">{{ chs.getText("timer_settings") }}</h1>

  <div class="container">
    <div class="row mb-2">
      <div class="col w-10"></div>
      <div class="col w-35 upper font-weight-bold">
        {{ chs.getText("ora") }}:
      </div>
      <div class="col w-35 upper font-weight-bold">
        {{ chs.getText("perc") }}:
      </div>
      <div class="col w-20 upper font-weight-bold">
        {{ chs.getText("aktiv") }}
      </div>
    </div>
    <div class="row mb-4">
      <div class="col w-10 font-weight-bold">{{ chs.getText("be") }}:</div>
      <div class="col w-35 upper">
        <app-num-selector
          [minVal]="0"
          [maxVal]="23"
          [value]="timerObj['ora_on']"
          (onChange)="initSendTimer($event, 'ora_on')"
        ></app-num-selector>
      </div>
      <div class="col w-35 upper">
        <app-num-selector
          [minVal]="0"
          [maxVal]="59"
          [value]="timerObj['perc_on']"
          (onChange)="initSendTimer($event, 'perc_on')"
        ></app-num-selector>
      </div>
      <div class="col w-20 upper flex-center">
        <input
          type="checkbox"
          [(ngModel)]="timerObj['bekapcs']"
          (change)="sendChange()"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col w-10 font-weight-bold">{{ chs.getText("ki") }}:</div>
      <div class="col w-35 upper">
        <app-num-selector
          [minVal]="0"
          [maxVal]="23"
          [value]="timerObj['ora_off']"
          (onChange)="initSendTimer($event, 'ora_off')"
        ></app-num-selector>
      </div>
      <div class="col w-35 upper">
        <app-num-selector
          [minVal]="0"
          [maxVal]="59"
          [value]="timerObj['perc_off']"
          (onChange)="initSendTimer($event, 'perc_off')"
        ></app-num-selector>
      </div>
      <div class="col w-20 upper flex-center">
        <input
          type="checkbox"
          [(ngModel)]="timerObj['kikapcs']"
          (change)="sendChange()"
        />
      </div>
    </div>
  </div>

  <div class="flex center-content">
    <a routerLink="/home" class="btn thin w-fit mt-3">{{
      chs.getText("fooldal")
    }}</a>
  </div>
</div>

<img class="bg-img" src="assets/background/details_bg.svg" />
