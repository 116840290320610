<div class="page-wrapper">
  <h2>{{ chs.getText("ac_egyseg_torlese") }}</h2>

  <form class="margin-top-50">
    <div class="form-row">
      <label class="upper">{{ chs.getText("egyseg_id") }}:</label>
      <input
        type="text"
        class="upper"
        [mask]="'AA-AA-AA-AA-AA-AA'"
        placeholder="AA-AA-AA-AA-AA-AA"
      />
    </div>

    <p class="margin-top-30" *ngIf="confirm">
      {{ chs.getText("ac_egyseg_torles_megerosites") }}
    </p>

    <div class="space-content" *ngIf="confirm">
      <button class="margin-top-20 thin" (click)="confirmDel(true)">
        {{ chs.getText("torles") }}
      </button>
      <button class="margin-top-20 thin" (click)="confirmDel(false)">
        {{ chs.getText("megsem") }}
      </button>
    </div>

    <div
      class="center-content margin-top-30"
      (click)="initDel()"
      *ngIf="!confirm"
    >
      <button class="margin-top-20 thin">{{ chs.getText("torles") }}</button>
    </div>
  </form>

  <hr />

  <div class="bottom-holder">
    <div class="center-content">
      <a class="btn thin" routerLink="/ac-units">{{ chs.getText("vissza") }}</a>
    </div>
  </div>
  <img class="bg-img" src="assets/background/register_bg.svg" />
</div>
