<div class="page-wrapper">
  <img src="assets/background/email_confirm_bg.svg" />

  <ng-container *ngIf="type == 'default'">
    <h1>
      Welcome <br />
      to MBC Marine
    </h1>
    <p>
      A confirmation email has been sent. Please click on the link provided in
      the email to activate your account.
    </p>

    <div class="center-content">
      <a href="/login" class="btn">{{ chs.getText("ok") }}</a>
    </div>
  </ng-container>

  <ng-container *ngIf="type == 'registration'">
    <h1>
      Welcome <br />
      to MBC Marine
    </h1>
    <p>
      A confirmation email has been sent. Please click on the link provided in
      the email to activate your account.
    </p>

    <div class="center-content">
      <a routerLink="/login" class="btn">{{ chs.getText("ok") }}</a>
    </div>
  </ng-container>

  <ng-container *ngIf="type == 'password-confirm'">
    <h1>Password changed!</h1>
    <p>You can now log in using your new password.</p>

    <div class="center-content">
      <a routerLink="/login" class="btn">{{ chs.getText("belepes") }}</a>
    </div>
  </ng-container>

  <ng-container *ngIf="type == 'password-reset'">
    <h1>Password reset mail sent</h1>
    <p>
      A confirmation email has been sent. Please click on the link provided in
      the email to reset your password.
    </p>

    <div class="center-content">
      <a routerLink="/login" class="btn">{{ chs.getText("ok") }}</a>
    </div>
  </ng-container>
</div>
