<div class="page-wrapper">
  <div class="end-content">
    <app-lang-selector></app-lang-selector>
  </div>
  <h1>
    Welcome <br class="on-mobile" />
    to MBC Marine
  </h1>
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="form-row">
      <label>{{ chs.getText("email") }}:</label>
      <input
        type="email"
        formControlName="email"
        [ngClass]="{
          'is-invalid': submitted && loginForm.controls.email.errors
        }"
      />
    </div>
    <div *ngIf="hasError('email')">
      <div
        class="input-error"
        *ngIf="loginForm.controls['email'].errors?.invalid"
      >
        {{ loginForm.controls["email"].errors?.message }}
      </div>
    </div>
    <div class="form-row">
      <label>{{ chs.getText("jelszo") }}:</label>
      <input
        type="password"
        formControlName="password"
        [ngClass]="{
          'is-invalid': submitted && loginForm.controls.password.errors
        }"
      />
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <div class="center-content">
      <button type="submit">{{ chs.getText("belepes") }}</button>
    </div>
  </form>

  <a routerLink="/reset-password">{{ chs.getText("elfelejtett_jelszo") }}</a>
  <a routerLink="/register">{{ chs.getText("regisztracio") }}</a>

  <img class="bg-img" src="assets/background/home_bg.svg" />
</div>
