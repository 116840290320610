import { Component, OnInit } from '@angular/core';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ac-timer',
  templateUrl: './ac-timer.component.html',
  styleUrls: ['./ac-timer.component.scss'],
})
export class AcTimerComponent implements OnInit {
  public onActive: boolean = false;
  public offActive: boolean = false;

  public timerObj = {
    ora_on: 0,
    perc_on: 0,
    ora_off: 0,
    perc_off: 0,
    kikapcs: 0,
    bekapcs: 0,
  };

  private _bufferTimer = null;
  private _bufferTime = 300;

  constructor(
    public chs: CommonHandlerService,
    private _infoService: InfoService
  ) {}

  ngOnInit(): void {
    if (this._infoService.localAcs.length > 0) {
      let ac = this._infoService.localAcs.find(
        (ac) => ac.id == this._infoService.ac
      );
      if (ac != undefined) {
        this._infoService.connectToWs(ac['mac']);
        this.timerObj = {
          ora_on: ac['ora_on'],
          perc_on: ac['perc_on'],
          ora_off: ac['ora_off'],
          perc_off: ac['perc_off'],
          kikapcs: ac['kikapcs'],
          bekapcs: ac['bekapcs'],
        };
      }
    }
  }

  initSendTimer(newValue: Event, ind: string) {
    if (this._bufferTimer != null) {
      clearTimeout(this._bufferTimer);
    }
    this.timerObj[ind] = newValue;
    this._bufferTimer = setTimeout(
      this.sendChange.bind(this),
      this._bufferTime
    );
  }

  sendChange() {
    this._bufferTimer = null;
    this.timerObj['kikapcs'] = this.timerObj['kikapcs'] ? 1 : 0;
    this.timerObj['bekapcs'] = this.timerObj['bekapcs'] ? 1 : 0;
    this._infoService.sendAcTimerState(this.timerObj);
  }

  isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
}
