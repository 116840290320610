export enum MAIN_INFO {
  ONOFF = 0,
  MODE,
  SET_TEMP,
  FAN,
  TEMP,
  ERR,
  CF,
  ECO_SETTING,
}

export enum MAIN_INFO_NAMES {
  ONOFF = 'onoff',
  MODE = 'mode',
  SET_TEMP = 'setTemperature',
  FAN = 'fanSpeed',
  TEMP = 'temperature',
  ERR = 'isError',
  SCALE = 'temperatureScale',
  LANG = 'language',
}

export enum CONFIGS {
  LANG = 'language',
  TEMPERATURE_SCALE = 'temperatureScale',
  AC_NAME = 'acName',
  BOAT_NAME = 'boatName',
}

export enum ACTIONS {
  FACTORY_RESET = 'resetfactory',
  RESTART = 'reset',
  SW_UPDATE = 'fwupdate',
}

export enum FAN_STEPS {
  TYPE1 = 3,
  TYPE2 = 5,
}

export enum AC_TYPES {
  AC = 'AC',
  DC = 'DC',
}
