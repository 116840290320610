import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';

@Component({
  selector: 'app-serias',
  templateUrl: './serias.component.html',
  styleUrls: ['./serias.component.scss'],
})
export class SeriasComponent implements OnInit {
  public serials = [];
  public macFilter: string;
  public serialFilter: string;
  public mode: string = 'L';

  public newAcId: string;
  public newSerial: string;

  constructor(public apiCallService: ApiCallService) {}

  ngOnInit(): void {
    this.getSerials();
  }

  formatMAC(mac: string) {
    return mac.match(/.{2}/g).join('-');
  }

  getSerials() {
    this.apiCallService
      .apiGet<ApiResponse>('/api/ac-serial')
      .subscribe((response) => {
        if (response.isSuccess()) {
          this.serials = response.data;

          if (this.macFilter != null && this.macFilter.length > 0) {
            this.serials = this.serials.filter((serial) =>
              serial.mac.includes(this.macFilter)
            );
          }

          if (this.serialFilter != null && this.serialFilter.length > 0) {
            this.serials = this.serials.filter((serial) =>
              serial.serial.includes(this.serialFilter.toUpperCase())
            );
          }

          this.serials.sort(function (a, b) {
            if (a.mac < b.mac) {
              return -1;
            }
            if (a.mac > b.mac) {
              return 1;
            }
            return 0;
          });
        }
      });
  }

  set(event: Event, mac: string) {
    const formData = new FormData();
    formData.append('mac', mac);
    formData.append('serial', (event.target as HTMLInputElement).value);
    this.apiCallService
      .apiPut<ApiResponse>('/api/ac-serial', formData)
      .subscribe((response) => {
        if (!response.isSuccess()) {
          alert('Sikertelen frissítés!');
        }
      });
  }

  setNew() {
    const formData = new FormData();
    formData.append('mac', this.newAcId.replace(/-/g, ''));
    formData.append('serial', this.newSerial);
    this.apiCallService
      .apiPut<ApiResponse>('/api/ac-serial', formData)
      .subscribe((response) => {
        if (!response.isSuccess()) {
          alert('Sikertelen rögzítés!');
        } else {
          this.newAcId = null;
          this.newSerial = null;
          this.getSerials();
          this.mode = 'L';
        }
      });
  }
}
