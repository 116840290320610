import { map } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { ApiResponse } from './api-response/api-response';

export class WSClient {
  private _controllerMac: string;
  private _websocket: WebSocketSubject<any> = null;

  constructor() {
    this._websocket = webSocket(environment.websocketUrl);
  }

  public get socket() {
    return this._websocket.asObservable().pipe(
      map((response) => {
        let res = new ApiResponse(response);
        return res;
      }),
    );
  }

  public get connected() {
    return this._websocket != null;
  }

  public checkMac(acMac: string) {
    return this._controllerMac == acMac;
  }

  connect(acMac: string, customerId: any = null) {
    if (this._controllerMac == acMac) {
      return;
    }
    this._controllerMac = acMac;
    this._websocket.next({
      listener: 'Web',
      data: {
        type: 'BrowserCon',
        Id: this._controllerMac,
      },
    });
    console.log('WS CONNECTED', {
      listener: 'Web',
      data: {
        type: 'BrowserCon',
        Id: this._controllerMac,
      },
    });

    //Customer id közlése
    /*if(customerId != null) {
            this._websocket.next({
                "listener": "Web",
                "data": {
                    "Id": this._controllerMac,
                    "type":"BrowserVer",
                    "data": {
                        "Id": this._controllerMac,
                        "customer": customerId
                    }
                }
            });

            console.log('WS CUSTOMER', {
                "listener": "Web",
                "data": {
                    "Id": this._controllerMac,
                    "type":"BrowserVer",
                    "data": {
                        "Id": this._controllerMac,
                        "customer": customerId
                    }
                }
            });
        }*/
  }

  adminConnect() {
    this._websocket.next({
      listener: 'Admin',
      data: {
        type: 'AdminCon',
      },
    });
    console.log('WS CONNECTED', {
      listener: 'Admin',
      data: {
        type: 'AdminCon',
      },
    });
  }

  disconnect() {
    this._websocket.complete();
    this._websocket = null;
    console.log('WS DISCONNECTED');
  }

  set(key: string, value: string) {
    let data = {};
    data['Id'] = this._controllerMac;
    data['type'] = 'BrowserMod';
    data[key] = value;
    this._websocket.next({
      listener: 'Web',
      data: data,
    });
    console.log('WS MESSAGE SENT (' + data['type'] + '):', data);
  }

  sendMessage(message: any) {
    let data = {};
    data['Id'] = this._controllerMac;
    data['type'] = 'BrowserMain';
    data = { ...message, ...data };
    this._websocket.next({
      listener: 'Web',
      data: data,
    });
    console.log('WS MESSAGE SENT (' + data['type'] + '):', data);
  }

  sendState(message: any) {
    let data = {};
    data['Id'] = this._controllerMac;
    data['type'] = 'BrowserVer';
    data['data'] = message;
    data['data']['Id'] = this._controllerMac;
    data['data']['type'] = 'acVer';
    this._websocket.next({
      listener: 'Web',
      data: data,
    });
    console.log('WS STATE SENT (' + data['type'] + '):', data);
  }

  sendAcTimerState(message: any) {
    let data = message;
    data['Id'] = this._controllerMac;
    data['type'] = 'BrowserMod';
    this._websocket.next({
      listener: 'Web',
      data: data,
    });
    console.log('AC TIMER STATE SENT (' + data['type'] + '):', {
      listener: 'Web',
      data: data,
    });
  }

  sendDirectMessage(message: any) {
    let data = {};
    data['Id'] = this._controllerMac;
    data['type'] = 'BrowserVer';
    data['data'] = JSON.parse(message);

    this._websocket.next({
      listener: 'Web',
      data: data,
    });
    console.log('WS DIRECT MESSAGE SENT', {
      listener: 'Web',
      data: data,
    });
  }

  ngDestroy() {
    console.log('WS DICONNECT');
    this.disconnect();
  }
}
