import { Component, OnInit } from '@angular/core';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-delete-ac-unit',
  templateUrl: './delete-ac-unit.component.html',
  styleUrls: ['./delete-ac-unit.component.scss'],
})
export class DeleteAcUnitComponent implements OnInit {
  confirm = false;

  constructor(public chs: CommonHandlerService) {}

  ngOnInit(): void {}

  initDel() {
    this.confirm = true;
  }

  confirmDel(confirm: boolean) {
    this.confirm = confirm;
  }
}
