import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error: string;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public chs: CommonHandlerService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.error = null;
  }

  ngOnInit(): void {}

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.error = this.chs.getText('kotelezo_mezok', true);
      return;
    }

    this.authService
      .login(this.loginForm.controls)
      .pipe(first())
      .subscribe((response) => {
        if (response.isSuccess()) {
          if (this.authService.user.isAdmin || this.authService.user.isDealer) {
            this.router.navigateByUrl('/admin/home');
          } else {
            this.router.navigateByUrl('/home');
          }
        } else {
          this.error = this.chs.handleError(this.loginForm, response);
        }
      });
  }

  hasError(input: string): boolean {
    return (
      this.loginForm.controls[input].invalid &&
      (this.loginForm.controls[input].dirty ||
        this.loginForm.controls[input].touched)
    );
  }
}
