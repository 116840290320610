<div class="page-wrapper">
  <h1 class="margin-bottom-16">{{ chs.getText("admin_menu_fejlec") }}</h1>

  <ul>
    <li class="margin-bottom-8">
      <a routerLink="/home">{{ chs.getText("admin_menu_home") }}</a>
    </li>
    <li class="margin-bottom-8">
      <a routerLink="/admin/overview">{{
        chs.getText("admin_menu_admin_tablazat")
      }}</a>
    </li>
    <li class="margin-bottom-8" restrictAccess [role]="'superuser'">
      <a routerLink="/admin/customers">{{
        chs.getText("admin_menu_admin_ugyfelek")
      }}</a>
    </li>
    <li class="margin-bottom-8" restrictAccess [role]="'superuser'">
      <a routerLink="/admin/users">{{
        chs.getText("admin_menu_admin_felhasznalok")
      }}</a>
    </li>
    <li class="margin-bottom-8" restrictAccess [role]="'owner'">
      <a routerLink="/admin/sw-upload">{{
        chs.getText("admin_menu_admin_verziok")
      }}</a>
    </li>
  </ul>
</div>
