import { Directive, Input, ElementRef } from '@angular/core';
import { AuthService } from './auth.service';
import { ROLE } from '../models/users/roles';

@Directive({
  selector: '[restrictAccess]',
})
export class AccessControlDirective {
  @Input() role: ROLE;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!!this.role && !this.authService.canAccess(this.role)) {
      const el: HTMLElement = this.elementRef.nativeElement;
      el.parentNode?.removeChild(el);
    }
  }
}
