import { EventEmitter, Injectable, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../models/api-response/api-response';
import { FAN_STEPS } from '../../models/configs';
import { WSClient } from '../../models/websocket';
import { ApiCallService } from '../api-call-service/api-call-service.service';
import { InfoService } from '../info-service/info.service';
import { User } from '../../models/users/user';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public controllers: any[] = [];
  private _websocket = null;

  public lostConnection: boolean = false;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _apiCallService: ApiCallService,
    private _infoService: InfoService
  ) {}

  /*API hívások*/
  getAdminControllers() {
    return this._apiCallService
      .apiGet<ApiResponse>('/api/admin-microcontrollers')
      .pipe(
        map((response) => {
          if (response.isSuccess()) {
            return response.data;
          }

          return [];
        })
      );
  }

  getManagedControllers() {
    return this._apiCallService
      .apiGet<ApiResponse>('/api/admin-microcontrollers/dealer')
      .pipe(
        map((response) => {
          if (response.isSuccess()) {
            return response.data;
          }

          return [];
        })
      );
  }

  getAdminController(controllerID: number) {
    return this._apiCallService
      .apiGet<ApiResponse>('/api/admin-microcontrollers')
      .pipe(
        map((response) => {
          if (response.isSuccess()) {
            let controller = response.data.find((c) => c.id == controllerID);
            return controller ?? null;
          }

          return null;
        })
      );
  }

  adminModControllerCustomer(controllerId: number, customerId: number) {
    const formData = new FormData();
    formData.append('microcontroller_id', controllerId.toString());
    formData.append(
      'customer_id',
      customerId == null ? '' : customerId.toString()
    );
    return this._apiCallService.apiPut<ApiResponse>(
      '/api/admin-microcontrollers',
      formData
    );
  }

  adminDisconnectControllerFromUser(controllerId: number) {
    const formData = new FormData();
    formData.append('microcontroller_id', controllerId.toString());
    return this._apiCallService.apiPut<ApiResponse>(
      '/api/admin-microcontrollers/disconnect',
      formData
    );
  }

  setFanSettings(controller: any, serviceObj: any) {
    const formData = new FormData();
    formData.append('type', serviceObj['type'].toString());
    formData.append('speed_1', serviceObj['speed_1'].toString());
    formData.append('speed_2', serviceObj['speed_2'].toString());
    formData.append('speed_3', serviceObj['speed_3'].toString());
    formData.append('speed_4', serviceObj['speed_4'].toString());
    formData.append('speed_5', serviceObj['speed_5'].toString());
    formData.append('fanSteps', serviceObj['fanSteps'].toString());
    formData.append('service_id', serviceObj['service_id'].toString());

    return this._apiCallService
      .apiPut<ApiResponse>('/api/microcontroller-services', formData)
      .pipe(
        map((result) => {
          if (result.isSuccess()) {
            let directWS = new WSClient();
            directWS.connect(controller.mac);
            directWS.socket.subscribe((_) => {
              directWS.sendDirectMessage(
                JSON.stringify({
                  Id: controller.mac,
                  service: {
                    id: serviceObj['service_id'],
                    microcontroller_id: controller.id,
                    fanSteps: serviceObj['fanSteps'],
                    type: serviceObj['type'],
                    speed_1: serviceObj['speed_1'],
                    speed_2: serviceObj['speed_2'],
                    speed_3: serviceObj['speed_3'],
                    speed_4: serviceObj['speed_4'],
                    speed_5: serviceObj['speed_5'],
                  },
                })
              );
            });
          }

          return result;
        })
      );
  }

  getUsers() {
    return this._apiCallService.apiGet<ApiResponse>('/api/users').pipe(
      map((response: ApiResponse) =>
        response.isSuccess() ? response.data : []
      ),
      map((users: any) => users.map((u) => User.createUser(u)))
    );
  }

  changeUserRole(user: User) {
    const formData = new FormData();
    formData.append('user_id', user.id.toString());
    formData.append('role', user.role);

    return this._apiCallService.apiPut<ApiResponse>('/api/users', formData);
  }

  /*Websocket*/
  public connectToWs() {
    if (this._websocket == null) {
      this._websocket = new WSClient();
      this._websocket.socket.subscribe(
        (wsResponse) => {
          this._handleWSMessages(wsResponse);
        },
        (error) => {
          console.error('WS ERROR', error);
          this.lostConnection = true;
        }
      );
    }
    this._websocket.adminConnect();
  }

  public sendDirectMessage(controller, customerId) {
    let directWS = new WSClient();
    directWS.connect(controller.mac);
    directWS.socket.subscribe((_) => {
      directWS.sendDirectMessage(
        JSON.stringify({ Id: controller.mac, customer: customerId.toString() })
      );
    });
  }

  private _handleWSMessages(message: ApiResponse) {
    if (message.data['ac']) {
      let acId = message.data['ac']['id'];
      let ind = this.controllers.findIndex(
        (controller) => controller.id == acId
      );
      if (-1 === ind) {
        //this.controllers = [...this.controllers, message.data['ac']];
      }
      this.controllers[ind] = message.data['ac'];
      this.controllers = [...this.controllers];
      //this.controllers = [...this.controllers.slice(0, ind), message.data['ac'], ...this.controllers.slice(ind + 1)];
      this.changeEvent.emit('x');
    }
  }

  /*Kisegítő funkciók*/
  getTemps(controller: any) {
    let decodedTemp = this._infoService.decodeTemp(controller['temperature']);
    let decodedSetTemp = this._infoService.decodeTemp(
      controller['setTemperature']
    );

    if (controller['temperatureScale'] == 'c') {
      return (
        Math.round(decodedTemp) + 'C / ' + Math.round(decodedSetTemp) + 'C'
      );
    } else {
      let tempF = this._infoService.C2F(decodedTemp);
      let setTempF = this._infoService.C2F(decodedSetTemp);
      return Math.round(tempF) + 'F / ' + Math.round(setTempF) + 'F';
    }
  }

  getDetailTemp(controller: any, ind: string) {
    let decoded = this._infoService.decodeTemp(controller[ind]);
    if (controller['temperatureScale'] == 'c') {
      return Math.round(decoded) + 'C';
    } else {
      let tempF = this._infoService.C2F(decoded);
      return Math.round(tempF) + 'F';
    }
  }

  getFan(controller: any) {
    if (controller['fanSteps'] >= FAN_STEPS.TYPE2) {
      let speed = controller['fanSpeed'] < 6 ? controller['fanSpeed'] : 5;
      return speed + ' / 5' + (controller['fanSpeed'] == 6 ? ' (AUTO)' : '');
    } else {
      let speed = controller['fanSpeed'] < 4 ? controller['fanSpeed'] : 3;
      return speed + ' / 3' + (controller['fanSpeed'] == 4 ? ' (AUTO)' : '');
    }
  }

  hasError(controller: any) {
    let hasError = false;
    for (let i = 1; i < 13; i++) {
      if (controller['error_' + i] == 1) {
        hasError = true;
      }
    }
    return hasError;
  }
}
