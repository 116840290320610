<div class="page-wrapper">
  <div class="end-content">
    <a class="btn thin noborder upper" routerLink="/home"
      ><img class="margin-right-8" src="assets/icons/house.svg" />{{
        chs.getText("fooldal")
      }}</a
    >
  </div>

  <div class="center-content upper">
    <h1>{{ chs.getText("ac_egysegek") }}</h1>
  </div>

  <form class="margin-top-30" [formGroup]="acForm">
    <div class="form-row ac-row">
      <label class="upper">{{ chs.getText("id") }}:</label>
      <ng-select
        appearance="outline"
        [searchable]="false"
        [items]="acList | async"
        bindLabel="label"
        bindValue="mac"
        formControlName="acId"
        (change)="getAcData()"
      >
      </ng-select>
    </div>

    <div class="form-row margin-top-30 ac-row">
      <label class="upper">{{ chs.getText("hajo") }}:</label>
      <ng-select
        appearance="outline"
        (change)="onChange()"
        [disabled]=""
        [searchable]="false"
        [items]="boatList | async"
        bindLabel="name"
        bindValue="id"
        formControlName="boatId"
      >
      </ng-select>
    </div>

    <div class="form-row margin-top-10 ac-row">
      <span class="font-italic">{{ chs.getText("hajo_magyarazat") }}</span>
    </div>

    <div class="form-row margin-top-20 ac-row">
      <label class="upper">{{ chs.getText("ac_neve") }}:</label>
      <input
        type="text"
        formControlName="acName"
        (keyup.enter)="onNameChange()"
        (blur)="onNameChange()"
      />
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <div *ngIf="message != null" class="message-holder">
      {{ message }}
    </div>
  </form>

  <div class="false-popup" *ngIf="confirm">
    <p class="margin-top-30">
      {{ chs.getText("ac_egyseg_torles_megerosites") }}
    </p>
    <div class="space-content">
      <button class="margin-top-20 thin" (click)="confirmDel(true)">
        {{ chs.getText("torles") }}
      </button>
      <button class="margin-top-20 thin" (click)="confirmDel(false)">
        {{ chs.getText("megsem") }}
      </button>
    </div>
  </div>

  <div class="holder margin-top-100" *ngIf="!confirm">
    <a class="btn thin btn-150 btn-center" routerLink="/new-ac-unit">{{
      chs.getText("uj_hozzaadasa")
    }}</a>
    <button class="btn thin btn-150 btn-center" (click)="onDelete()">
      {{ chs.getText("torles") }}
    </button>
  </div>

  <div class="bottom-holder">
    <app-ac-menu></app-ac-menu>
  </div>

  <img class="bg-img" src="assets/background/ac_bg.svg" />
</div>
