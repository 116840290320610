import { Component } from '@angular/core';
import { InfoService } from './core/services/info-service/info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'mbc-remote';

  constructor() {}
}
