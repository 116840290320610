import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.scss'],
})
export class ResendActivationComponent implements OnInit {
  resendForm: FormGroup;
  error: string;
  submitted: boolean = false;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private router: Router,
  ) {
    this.resendForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.error = null;
  }

  ngOnInit(): void {}

  resendEmail() {
    const formdata = new FormData();
    formdata.append('email', this.resendForm.get('email').value);
    formdata.append('password', this.resendForm.get('password').value);
    this.apiCallService
      .apiPost<ApiResponse>('/api/email-confirmation', formdata)
      .subscribe((apiResult) => {
        if (apiResult && apiResult.isSuccess()) {
          this.router.navigate(['success'], {
            state: { data: 'registration' },
          });
        } else {
          this.error = this.chs.handleError(this.resendForm, apiResult);
        }
      });
  }
}
