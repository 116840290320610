import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from './core/authentication/access.guard';
import { AcResolver } from './core/models/resolvers/ac.resolver';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout/default-layout.component';
import { AcTimerComponent } from './pages/ac-timer/ac-timer.component';
import { AcUnitsComponent } from './pages/ac-units/ac-units.component';
import { AccountComponent } from './pages/account/account.component';
import { AdminMenuComponent } from './pages/admin-menu/admin-menu.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AlertComponent } from './pages/alert/alert.component';
import { BoatsComponent } from './pages/boats/boats.component';
import { ConfirmResetComponent } from './pages/confirm-reset/confirm-reset.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { DeleteAcUnitComponent } from './pages/delete-ac-unit/delete-ac-unit.component';
import { DeleteBoatComponent } from './pages/delete-boat/delete-boat.component';
import { DetailsComponent } from './pages/details/details.component';
import { DirectMessageComponent } from './pages/direct-message/direct-message.component';
import { EmailVerifyComponent } from './pages/email-verify/email-verify.component';
import { FanSettingsComponent } from './pages/fan-settings/fan-settings.component';
import { FirmwareComponent } from './pages/firmware/firmware.component';
import { ForgottenPasswordComponent } from './pages/forgotten-password/forgotten-password.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NewAcUnitComponent } from './pages/new-ac-unit/new-ac-unit.component';
import { NewBoatComponent } from './pages/new-boat/new-boat.component';
import { RegistrationComponent } from './pages/registration/registration/registration.component';
import { ResendActivationComponent } from './pages/resend-activation/resend-activation.component';
import { SeriasComponent } from './pages/serials/serias/serias.component';
import { SuccessComponent } from './pages/success/success.component';
import { HelpComponent } from './pages/help/help.component';
import { ROLE } from './core/models/users/roles';
import { AdminUsers } from './pages/admin-users/admin-users.component';
import { AdminControlComponent } from './pages/admin-control/admin-control.component';

const routes: Routes = [
  {
    path: 'admin/overview',
    component: AdminComponent,
    data: {
      requiresLogin: true,
      role: ROLE.DEALER,
    },
    canActivate: [AccessGuard],
  },
  {
    path: '',
    children: [
      //ADMIN
      {
        path: 'admin',
        component: DefaultLayoutComponent,
        children: [
          {
            path: 'home',
            component: AdminMenuComponent,
          },
          {
            path: 'sw-upload',
            component: FirmwareComponent,
            data: {
              requiresLogin: true,
              role: ROLE.OWNER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'serials',
            component: SeriasComponent,
            data: {
              requiresLogin: true,
              role: ROLE.SUPERUSER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'fan-settings/:acId',
            component: FanSettingsComponent,
            data: {
              requiresLogin: true,
              role: ROLE.DEALER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'control/:acId',
            component: AdminControlComponent,
            data: {
              requiresLogin: true,
              role: ROLE.DEALER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'direct-message/:mac',
            component: DirectMessageComponent,
            data: {
              requiresLogin: true,
              role: ROLE.SUPERUSER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'customers',
            component: CustomersComponent,
            data: {
              requiresLogin: true,
              role: ROLE.SUPERUSER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'customers/:id',
            component: CustomersComponent,
            data: {
              requiresLogin: true,
              role: ROLE.SUPERUSER,
            },
            canActivate: [AccessGuard],
          },
          {
            path: 'users',
            component: AdminUsers,
            data: {
              requiresLogin: true,
              role: ROLE.SUPERUSER,
            },
            canActivate: [AccessGuard],
          },
        ],
        data: {
          requiresLogin: true,
          role: ROLE.DEALER,
        },
        canActivate: [AccessGuard],
      },
      {
        path: '',
        component: DefaultLayoutComponent,
        children: [
          {
            path: 'home',
            component: HomeComponent,
            resolve: {
              pageData: AcResolver,
            },
          },
          {
            path: 'details',
            component: DetailsComponent,
            resolve: {
              pageData: AcResolver,
            },
          },
          {
            path: 'timer',
            component: AcTimerComponent,
            resolve: {
              pageData: AcResolver,
            },
          },
          {
            path: 'alert',
            component: AlertComponent,
            resolve: {
              pageData: AcResolver,
            },
          },
          {
            path: 'account',
            component: AccountComponent,
          },
          {
            path: 'ac-units',
            component: AcUnitsComponent,
          },
          {
            path: 'new-ac-unit',
            component: NewAcUnitComponent,
          },
          {
            path: 'delete-ac-unit',
            component: DeleteAcUnitComponent,
          },
          {
            path: 'boats',
            component: BoatsComponent,
          },
          {
            path: 'new-boat',
            component: NewBoatComponent,
          },
          {
            path: 'delete-boat',
            component: DeleteBoatComponent,
          },
          {
            path: 'help',
            component: HelpComponent,
          },
          {
            path: '',
            redirectTo: '/home',
            pathMatch: 'full',
          },
        ],
        data: {
          requiresLogin: true,
        },
        canActivate: [AccessGuard],
      },
      {
        path: '',
        component: DefaultLayoutComponent,
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'register',
            component: RegistrationComponent,
          },
          {
            path: 'reset-password',
            component: ForgottenPasswordComponent,
          },
          {
            path: 'confirm-reset/:token',
            component: ConfirmResetComponent,
          },
          {
            path: 'email/verify/:id/:params',
            component: EmailVerifyComponent,
          },
          {
            path: 'resend-activation',
            component: ResendActivationComponent,
          },
          {
            path: 'success',
            component: SuccessComponent,
          },
          {
            path: '',
            redirectTo: '/login',
            pathMatch: 'full',
          },
        ],
        data: {
          requiresLogin: false,
        },
        canActivate: [AccessGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
