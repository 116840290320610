import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api-response/api-response';
import { ApiCallService } from '../../services/api-call-service/api-call-service.service';
import { InfoService } from '../../services/info-service/info.service';

@Injectable({
  providedIn: 'root',
})
export class AcResolver implements Resolve<any> {
  constructor(
    private apiCallService: ApiCallService,
    private router: Router,
    private infoService: InfoService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    //Csak akkor kell tényleges api hívás, ha a legutóbbi óta változott a
    //főképernyőn kiválasztott AC
    if (this.infoService.localAcChanged) {
      return this.apiCallService
        .apiGet<ApiResponse>('/api/microcontrollers')
        .pipe(
          catchError((err) => {
            let res = new ApiResponse({
              success: false,
              code: ['technikai_hiba'],
              extra: [],
            });
            return of(res);
          }),
          mergeMap((result) => {
            if (result && result.isSuccess()) {
              //Ha nincs AC, akkor redirect az AC felvevős oldalra
              if (result.data.length > 0) {
                this.infoService.localAcChanged = false;
                this.infoService.localAcs = result.data;
                //Lekérdezzük a local storage-ben tárolt AC id-ját, ha van
                //ha nincs, úgy kiválasztjuk az első ac id-ját
                let localAcCache = localStorage.getItem(environment.localAcInd);
                this.infoService.ac =
                  localAcCache == undefined
                    ? this.infoService.localAcs[0].id
                    : localAcCache;
                if (
                  this.infoService.ac != null &&
                  this.infoService.localAcs.find(
                    (ac) => ac.id == this.infoService.ac,
                  ) != undefined
                ) {
                  this.infoService.initRefresh();
                }
                return of(result.data);
              } else {
                this.router.navigate(['/ac-units']);
                return EMPTY;
              }
            } else {
              this.router.navigate(['/ac-units']);
              return EMPTY;
            }
          }),
        );
    } else {
      return of(this.infoService.localAcs);
    }
  }
}
