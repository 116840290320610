<div class="wrapper">
  <a *ngIf="!selectedUser" class="admin-home-link" routerLink="/admin/home"
    >&lt; HOME</a
  >
  <a
    *ngIf="!!selectedUser"
    class="admin-home-link"
    (click)="selectedUser = null"
    >&lt; BACK</a
  >

  <ng-container *ngIf="!selectedUser">
    <h1>{{ chs.getText("admin_menu_admin_felhasznalok") }}</h1>
    <div class="holder">
      <input
        class="filter"
        type="text"
        placeholder="Filter..."
        [(ngModel)]="filter"
      />

      <button class="btn thin" (click)="exportUsers()">
        {{ chs.getText("export") }}
      </button>
    </div>

    <div class="table-holder">
      <table>
        <thead>
          <th>{{ chs.getText("szerep") }}</th>
          <th>{{ chs.getText("nev") }}</th>
          <th>{{ chs.getText("email") }}</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of users">
            <tr *ngIf="checkFilter(user)" (click)="selectUser(user)">
              <td
                class="clickable"
                [ngClass]="{
                  superuser: user.isAdmin,
                  dealer: user.isDealer && !user.isAdmin,
                  user: !user.isDealer && !user.isAdmin
                }"
              >
                {{ user.role }}
              </td>
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.email }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedUser">
    <h1>{{ selectedUser.first_name }} {{ selectedUser.last_name }}</h1>
    <label>{{ chs.getText("szerep") }}:</label>
    <ng-select
      appearance="outline"
      [searchable]="false"
      [items]="roles"
      [(ngModel)]="selectedUser.role"
      (change)="changeRole()"
      (blur)="selectedUser = null"
    ></ng-select>

    <h2 class="margin-top-20">{{ chs.getText("saját_controllerek") }}</h2>
    <div class="margin-bottom-20">
      {{ chs.getText("saját_controllerek_tip") }}
    </div>
    <table>
      <thead>
        <th>{{ chs.getText("mac") }}</th>
        <th>{{ chs.getText("ugyfel") }}</th>
        <th></th>
      </thead>
      <tbody>
        <tr *ngFor="let controller of filterUserControllers()">
          <td>
            <a
              [routerLink]="'/admin/control/' + controller.id"
              target="_blank"
              >{{ controller.mac | mac }}</a
            >
          </td>
          <td (click)="selectedController = controller">
            <span
              *ngIf="
                !selectedController || selectedController.id !== controller.id
              "
              >{{ getCustomerName(controller.customer_id) }}</span
            >
            <ng-select
              *ngIf="
                !!selectedController && selectedController.id === controller.id
              "
              appearance="outline"
              [searchable]="false"
              [items]="customers"
              [(ngModel)]="selectedController.customer_id"
              bindLabel="name"
              bindValue="customer_id"
              (change)="changeCustomerId()"
            ></ng-select>
          </td>
          <td>
            <button
              class="btn thin"
              (click)="disconnectControllerFormUser(controller.id)"
            >
              {{ chs.getText("remote_levalasztasa") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <h2 class="margin-top-20">{{ chs.getText("kezelt_kontrollerek") }}</h2>
    <div class="margin-bottom-20">
      {{ chs.getText("kezelt_kontrollerek_tip") }}
    </div>
    <table>
      <thead>
        <th>{{ chs.getText("mac") }}</th>
        <th>{{ chs.getText("ugyfel") }}</th>
        <th>{{ chs.getText("tulajdonos") }}</th>
      </thead>
      <tbody>
        <tr *ngFor="let controller of filterUserManagedControllers()">
          <td>{{ controller.mac | mac }}</td>
          <td>{{ getCustomerName(controller.customer_id) }}</td>
          <td>{{ getOwnerName(controller.userId) }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
