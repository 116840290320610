import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from '../../models/api-response/api-response';

@Injectable({
  providedIn: 'root',
})
export class ApiCallService {
  protected options: {
    observe: 'response';
    responseType: 'json';
  };

  constructor(private httpClient: HttpClient) {}

  public apiPost<T>(
    url: string,
    body?: any,
    options?: {},
    pathIsAbsolute: boolean = false
  ): Observable<ApiResponse> {
    return this.httpClient
      .post<ApiResponse>(
        pathIsAbsolute ? url : environment.apiUrl + url,
        body,
        this.mergeOptions(options)
      )
      .pipe(
        catchError((err) => {
          let res = new ApiResponse({
            success: false,
            code: ['technikai_hiba'],
            extra: [],
          });
          return of(res);
        })
      );
  }

  public apiDelete<T>(
    url: string,
    body?: FormData,
    pathIsAbsolute: boolean = false
  ): Observable<T> {
    if (body == undefined) {
      body = new FormData();
    }
    body.append('_method', 'DELETE');
    return this.httpClient.post<T>(
      pathIsAbsolute ? url : environment.apiUrl + url,
      body
    );
  }

  public apiUpdate<T>(
    url: string,
    id: number,
    body?: FormData,
    pathIsAbsolute: boolean = false
  ): Observable<T> {
    body.append('_method', 'PATCH');
    return this.httpClient.post<T>(
      pathIsAbsolute ? url + '/' + id : environment.apiUrl + url + '/' + id,
      body
    );
  }

  public apiPut<T>(
    url: string,
    body?: FormData,
    pathIsAbsolute: boolean = false
  ): Observable<T> {
    body.append('_method', 'PUT');
    return this.httpClient.post<T>(
      pathIsAbsolute ? url : environment.apiUrl + url,
      body
    );
  }

  public apiGet<T>(
    url: string,
    pathIsAbsolute: boolean = false,
    getParams: object = {}
  ): Observable<T> {
    if (Object.keys(getParams).length !== 0) {
      let params = new HttpParams();
      Object.entries(getParams).map(([key, val]) => {
        params = params.append(key, val);
      });
      return this.httpClient.get<T>(
        pathIsAbsolute ? url : environment.apiUrl + url,
        { params: params }
      );
    } else {
      return this.httpClient.get<T>(
        pathIsAbsolute ? url : environment.apiUrl + url
      );
    }
  }

  private mergeOptions(option: { [x: string]: any }) {
    for (let i in this.options) {
      if (typeof option[i] === 'undefined') {
        option[i] = this.options[i];
      }
    }
    return option;
  }
}
