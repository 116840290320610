<div class="page-wrapper" *ngIf="pageMode == 'L'">
  <div class="center-content">
    <h1>{{ chs.getText("ac_hibak") }}</h1>
  </div>

  <div class="errors margin-top-30">
    <div *ngFor="let error of infoService.getErrorList(acType)">
      <div class="error" (click)="showErrorDetails(error)">
        {{ error.text }}
      </div>
    </div>
  </div>

  <div class="bottom-holder">
    <app-home-menu [errorMode]="!infoService.connected"></app-home-menu>
  </div>
</div>

<div class="page-wrapper" *ngIf="pageMode == 'D'">
  <div class="center-content">
    <h1 class="error-title">{{ selectedError.text }}</h1>
  </div>

  <div
    *ngIf="acType == 'AC'"
    class="details"
    [innerHtml]="
      chs.getText(acType + '_error_details_' + selectedError.index, true)
    "
  ></div>

  <div
    *ngIf="acType == 'DC'"
    class="details"
    [innerHtml]="
      chs.getText(
        acType + '_error_details_' + selectedError.index + '_dc',
        true
      )
    "
  ></div>

  <div class="center-content">
    <button class="margin-top-20 margin-bottom-20 thin" (click)="back()">
      {{ chs.getText("vissza") }}
    </button>
  </div>
</div>
