import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../models/users/user';
import { ApiResponse } from '../models/api-response/api-response';
import { Router } from '@angular/router';
import { ApiCallService } from '../services/api-call-service/api-call-service.service';
import { ROLE } from '../models/users/roles';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private apiCallService: ApiCallService
  ) {
    const loginData = JSON.parse(
      localStorage.getItem(environment.userStorageIndex)
    );
    if (!!loginData?.token) {
      this.user = User.createUser(loginData);
    }
  }

  public get isLoggedIn(): boolean {
    return !!this.user?.token;
  }

  canAccess(role: ROLE) {
    switch (role) {
      case ROLE.OWNER:
        return this.user.isOwner;
      case ROLE.SUPERUSER:
        return this.user.isAdmin;
      case ROLE.DEALER:
        return this.user.isDealer || this.user.isAdmin;
    }
  }

  login(loginData: any) {
    const formData = new FormData();
    formData.append('email', loginData.email.value);
    formData.append('password', loginData.password.value);

    return this.httpClient
      .post(`${environment.apiUrl}/api/auth/login`, formData)
      .pipe(
        switchMap((loginResponse: ApiResponse) => {
          if (loginResponse.isSuccess()) {
            this.user = new User(loginResponse.data['token']);
            return this.apiCallService
              .apiGet<ApiResponse>('/api/logged-in-user')
              .pipe(
                tap((userResponse) => {
                  if (userResponse.isSuccess()) {
                    this.user.userData = userResponse.data;
                    localStorage.setItem(
                      environment.userStorageIndex,
                      JSON.stringify(this.user)
                    );
                  }
                })
              );
          } else {
            return of(loginResponse);
          }
        })
      );
  }

  logout() {
    return this.apiCallService.apiPost('/api/auth/logout').pipe(
      tap((response: ApiResponse) => {
        if (response.isSuccess()) {
          localStorage.removeItem(environment.userStorageIndex);
          this.user = null;
          this.router.navigate(['/login']);
        }
      })
    );
  }
}
