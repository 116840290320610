<div class="wrapper">
  <a *ngIf="!selectedCustomer" class="admin-home-link" routerLink="/admin/home">
    &lt; HOME</a
  >
  <a *ngIf="!!selectedCustomer" class="admin-home-link" (click)="resetEdit()">
    &lt; BACK</a
  >
  <h1 *ngIf="!selectedCustomer">
    {{ chs.getText("admin_menu_admin_ugyfelek") }}
  </h1>
  <h1 *ngIf="!!selectedCustomer">{{ selectedCustomer.name }}</h1>

  <ng-container *ngIf="!selectedCustomer">
    <input
      class="filter"
      type="text"
      placeholder="Filter..."
      [(ngModel)]="filter"
    />

    <table>
      <thead>
        <th>{{ chs.getText("admin_ugyfelek_nev") }}</th>
        <th>{{ chs.getText("admin_ugyfelek_id") }}</th>
        <th></th>
      </thead>
      <tbody>
        <ng-container *ngFor="let customer of customers">
          <tr *ngIf="checkFilter(customer)" (click)="editCustomer(customer)">
            <td>{{ customer.name }}</td>
            <td>{{ customer.customer_id }}</td>
            <td>
              <a (click)="deleteCustomer($event, customer)">{{
                chs.getText("admin_ugyfelek_torles")
              }}</a>
            </td>
            <td></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>

  <div class="form-wrapper margin-top-30">
    <h2 *ngIf="!selectedCustomer">{{ chs.getText("admin_ugyfelek_uj") }}</h2>
    <h2 *ngIf="!!selectedCustomer">
      {{ chs.getText("admin_ugyfelek_szerkeszt") }}
    </h2>
    <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
      <div class="form-row margin-top-30">
        <label>{{ chs.getText("admin_ugyfelek_nev") }}:</label>
        <input type="text" formControlName="name" />
        <div *ngIf="submitted && !!customerForm.get('name').errors">
          <div
            class="input-error"
            *ngIf="customerForm.get('name').errors.required"
          >
            {{ chs.getText("required") }}
          </div>
        </div>
      </div>

      <div class="form-row margin-top-30">
        <label>{{ chs.getText("admin_ugyfelek_id") }}:</label>
        <input type="number" min="0" formControlName="customer_id" />
        <div *ngIf="submitted && !!customerForm.get('customer_id').errors">
          <div
            class="input-error"
            *ngIf="customerForm.get('customer_id').errors.required"
          >
            {{ chs.getText("required") }}
          </div>
          <div
            class="input-error"
            *ngIf="
              customerForm.get('customer_id').errors.min ||
              customerForm.get('customer_id').errors.max
            "
          >
            {{ chs.getText("admin_ugyfelek_id_hiba") }}
          </div>
        </div>
      </div>

      <div class="space-content margin-top-30">
        <button type="submit" class="thin btn-center btn-100">
          <span *ngIf="!selectedCustomer">{{
            chs.getText("admin_ugyfelek_mentes")
          }}</span>
          <span *ngIf="!!selectedCustomer">{{
            chs.getText("admin_ugyfelek_szerk_mentes")
          }}</span>
        </button>
      </div>
    </form>
  </div>

  <ng-container *ngIf="!!selectedCustomer">
    <h2 class="margin-top-20 margin-bottom-20">
      {{ chs.getText("admin_ugyfelek_egysegei") }}
    </h2>
    <table>
      <thead>
        <th>{{ chs.getText("mac") }}</th>
        <th>{{ chs.getText("tulajdonos") }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let controller of getCustomerControllers()">
          <tr>
            <td>{{ controller.mac | mac }}</td>
            <td>{{ getOwnerName(controller.userId) }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div class="form-wrapper margin-top-20">
      <form [formGroup]="acForm">
        <div class="form-row">
          <label>{{ chs.getText("admin_ugyfelek_uj_egyseg_hozzaadas") }}</label>
          <input
            type="text"
            class="upper"
            [mask]="'AA-AA-AA-AA-AA-AA'"
            placeholder="AA-AA-AA-AA-AA-AA"
            formControlName="acId"
          />
        </div>
        <div class="space-content margin-top-30">
          <button (click)="addNewController()" class="thin btn-center btn-100">
            {{ chs.getText("hozzaadas") }}
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</div>
