import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent implements OnInit {
  langList: any;
  langForm: FormControl;

  constructor(
    private chs: CommonHandlerService,
    private formBuilder: FormBuilder,
  ) {
    let lang = this.chs.lang == null ? 'EN' : this.chs.lang;
    this.langForm = this.formBuilder.control(lang);
  }

  ngOnInit(): void {
    this.langList = this.chs.getLangList();

    this.langForm.valueChanges.subscribe((val) => {
      this.chs.setLang(val);
    });
  }
}
