<div class="page-wrapper">
  <h2>{{ chs.getText("jelszo_visszaallitas") }}</h2>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
    <div class="form-row">
      <label>{{ chs.getText("email") }}:</label>
      <input
        type="email"
        formControlName="email"
        [ngClass]="{
          'is-invalid': submitted && resetPasswordForm.controls.email.errors
        }"
      />
    </div>
    <div *ngIf="hasError('email')">
      <div
        class="input-error"
        *ngIf="resetPasswordForm.controls['email'].errors.email"
      >
        {{ chs.getText("err__email", true) }}
      </div>
      <div
        class="input-error"
        *ngIf="resetPasswordForm.controls['email'].errors.invalid"
      >
        {{
          chs.getText(resetPasswordForm.controls["email"].errors.message, true)
        }}
      </div>
    </div>
    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>
    <div *ngIf="message != null" class="message-holder">
      {{ message }}
    </div>

    <div class="center-content bottom-holder">
      <button type="submit">{{ chs.getText("uj_jelszo_igenyles") }}</button>
    </div>
  </form>
</div>
