import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { CONFIGS } from 'src/app/core/models/configs';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { ApiResponseInterceptor } from 'src/app/core/services/api-call-service/api-response.interceptor';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-ac-units',
  templateUrl: './ac-units.component.html',
  styleUrls: ['./ac-units.component.scss'],
})
export class AcUnitsComponent implements OnInit {
  acList: Observable<any[]>;
  acArr = [];
  boatList: Observable<object[]>;
  boatArr = [];
  error: string;
  message: string;
  confirm: boolean = false;
  acForm: FormGroup;

  constructor(
    public chs: CommonHandlerService,
    private apiCallService: ApiCallService,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
  ) {
    this.acForm = this.formBuilder.group({
      acId: [null],
      acName: [''],
      boatId: [''],
    });
  }

  ngOnInit(): void {
    this.getAcList();

    this.boatList = this.apiCallService.apiGet<ApiResponse>('/api/boats').pipe(
      mergeMap((response) => {
        if (response && response.isSuccess()) {
          this.boatArr = response.data;
          return of(response.data);
        } else {
          this.boatArr = [];
          return EMPTY;
        }
      }),
    );
  }

  get acId() {
    return this.acForm.get('acId').value;
  }

  getAcData() {
    if (this.acForm.get('acId').value != null) {
      let selectedAc = this.acArr.find(
        (ac) => ac['mac'] == this.acForm.get('acId').value,
      );
      this.acForm.get('acName').setValue(selectedAc['name']);
      this.acForm.get('boatId').setValue(selectedAc['boatId']);
      this.error = null;

      this.infoService.connectToWs(this.acForm.get('acId').value);
    }
  }

  onChange() {
    if (this.acForm.get('acId').value != null) {
      const formData = new FormData();
      let boatId =
        this.acForm.get('boatId').value == null
          ? ''
          : this.acForm.get('boatId').value;
      formData.append('mac', this.acForm.get('acId').value);
      formData.append('boat_id', boatId);
      this.apiCallService
        .apiPut<ApiResponse>('/api/microcontrollers/connectBoat', formData)
        .subscribe((response) => {
          //TODO set
          //this.infoService.sendConfig(CONFIGS.AC_NAME, this.acForm.get('acName').value, this.acForm.get('acId').value);
          //setTimeout(() => {this.getAcList(true)}, 2000);
          if (!response.isSuccess()) {
            this.error = this.chs.handleError(this.acForm, response);
          } else {
            this.infoService.localAcChanged = true;
          }
        });
    }
  }

  onNameChange() {
    if (this.acForm.get('acId').value != null) {
      let name = this.acForm.get('acName').value;
      this.infoService.sendWSMessage('name', name);
      this.infoService.localAcChanged = true;
    }
  }

  onDelete() {
    if (this.acForm.get('acId').value != null) {
      this.confirm = true;
      this.acForm.disable();
      /*} else if(this.infoService.localAcs.length == 0) {
      this.error = this.chs.getText('nincs_torolheto_ac', true);*/
    } else {
      this.error = this.chs.getText('valassz_ki_act', true);
    }
  }

  confirmDel(confirm: boolean) {
    this.confirm = confirm;
    this.acForm.enable();
    if (confirm && this.acForm.get('acId').value != null) {
      this.confirm = true;
      const formData = new FormData();
      formData.append('mac', this.acForm.get('acId').value);
      this.apiCallService
        .apiPut<ApiResponse>('/api/microcontrollers/disconnect', formData)
        .subscribe((result) => {
          this.confirm = false;
          if (result && result.isSuccess()) {
            this.getAcList();
            this.acForm.get('acName').setValue('');
            this.acForm.get('boatId').setValue(null);
            this.acForm.get('acId').setValue(null);
            this.infoService.localAcChanged = true;
            this.message = this.chs.getText('sikeres_ac_torles', false);
          } else {
            this.error = this.chs.getText('ac_torles_hiba', true);
          }
        });
    } else if (confirm) {
      this.error = this.chs.getText('valassz_ki_act', true);
    }
  }

  getAcList(isEdit: boolean = false) {
    this.acList = this.apiCallService
      .apiGet<ApiResponse>('/api/microcontrollers')
      .pipe(
        mergeMap((response) => {
          if (response && response.isSuccess()) {
            this.acArr = response.data;
            for (let i in this.acArr) {
              this.acArr[i]['label'] = this.acArr[i]['mac']
                .match(/.{2}/g)
                .join(':');
            }
            if (this.acArr.length > 0 && !isEdit) {
              this.acForm.get('acId').setValue(this.acArr[0].mac);
              this.getAcData();
            }

            return of(this.acArr);
          } else {
            this.acArr = [];
            return EMPTY;
          }
        }),
      );
  }
}
