import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { ApiResponseInterceptor } from 'src/app/core/services/api-call-service/api-response.interceptor';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-delete-boat',
  templateUrl: './delete-boat.component.html',
  styleUrls: ['./delete-boat.component.scss'],
})
export class DeleteBoatComponent implements OnInit {
  submitted = false;
  confirm = false;
  deleteForm: FormGroup;
  error: string = null;
  message: string = null;
  selectedBoat: object = null;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
  ) {
    this.deleteForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  confirmDel(confirm: boolean) {
    this.confirm = confirm;
    this.deleteForm.enable();
    if (confirm && this.selectedBoat != null) {
      const formdata = new FormData();
      formdata.append('boat_id', this.selectedBoat['id']);
      this.apiCallService
        .apiDelete<ApiResponse>('/api/boats', formdata)
        .subscribe((result) => {
          if (result && result.isSuccess()) {
            this.message = this.chs.getText('hajo_torlese_sikeres');
          } else {
            this.error = this.chs.getText('hajo_torlese_sikertelen', true);
          }
        });
      this.confirm = false;
      this.selectedBoat = null;
      this.deleteForm.get('name').setValue('');
      this.deleteForm.get('name').setErrors(null);
    }
  }

  initDel() {
    this.submitted = true;
    if (this.deleteForm.invalid) {
      this.error = this.chs.getText('kotelezo_mezok', true);
      return;
    }

    this.apiCallService
      .apiGet<ApiResponse>('/api/boats')
      .subscribe((response) => {
        if (response && response.isSuccess()) {
          for (let i in response.data) {
            if (response.data[i].name == this.deleteForm.get('name').value) {
              this.selectedBoat = response.data[i];
            }
          }

          if (this.selectedBoat == null) {
            this.error = this.chs.getText('hajo_nem_talalhato', true);
            return;
          }
          this.deleteForm.disable();
          this.error = null;
          this.confirm = true;
        }
      });
  }
}
