<div class="page-wrapper">
  <div class="end-content">
    <a class="btn thin noborder upper" routerLink="/home"
      ><img class="margin-right-8" src="assets/icons/house.svg" />
      {{ chs.getText("fooldal") }}</a
    >
  </div>
  <div class="center-content">
    <h1>{{ chs.getText("felhasznalo_adatok") }}</h1>
  </div>

  <form
    id="password-change-form"
    [formGroup]="passwordChangeForm"
    class="close-label"
    (ngSubmit)="passwordChange()"
  >
    <div class="form-row">
      <label>{{ chs.getText("uj_jelszo") }}:</label>
      <input type="password" formControlName="password" />
    </div>
    <div *ngIf="hasErrorPasswordChange('password')">
      <div
        class="input-error"
        *ngIf="passwordChangeForm.controls['password'].errors.minlength"
      >
        {{ chs.getText("rovid_jelszo", true) }}
      </div>
    </div>

    <div class="form-row">
      <label>{{ chs.getText("uj_jelszo_ismet") }}:</label>
      <input
        type="password"
        formControlName="passwordRe"
        (blur)="checkConfirm()"
      />
    </div>
    <div *ngIf="hasErrorPasswordChange('passwordRe')">
      <div
        class="input-error"
        *ngIf="passwordChangeForm.controls['passwordRe'].errors.minlength"
      >
        {{ chs.getText("rovid_jelszo", true) }}
      </div>
      <div
        class="input-error"
        *ngIf="passwordChangeForm.controls['passwordRe'].errors.mismatch"
      >
        {{ chs.getText("jelszo_megerosites_hibas", true) }}
      </div>
    </div>

    <div class="end-content">
      <div *ngIf="errorPasswordChange != null" class="error-holder">
        {{ errorPasswordChange }}
      </div>
      <div *ngIf="messagePasswordChange != null" class="message-holder">
        {{ messagePasswordChange }}
      </div>
      <button type="submit" class="upper thin">
        {{ chs.getText("megvaltoztatas") }}
      </button>
    </div>
  </form>

  <form
    id="user-data-form"
    [formGroup]="userDataForm"
    class="close-label"
    (ngSubmit)="changeUserData()"
  >
    <div class="form-row">
      <label>{{ chs.getText("email") }}:</label>
      <input type="email" formControlName="email" maxlength="255" />
    </div>
    <div *ngIf="hasErrorUserData('email')">
      <div
        class="input-error"
        *ngIf="userDataForm.controls['email'].errors.email"
      >
        {{ chs.getText("err__email", true) }}
      </div>
    </div>
    <div class="form-row">
      <label>{{ chs.getText("utonev") }}:</label>
      <input type="text" formControlName="firstName" maxlength="255" />
    </div>
    <div class="form-row">
      <label>{{ chs.getText("vezeteknev") }}:</label>
      <input type="text" formControlName="lastName" maxlength="255" />
    </div>

    <div class="end-content">
      <div *ngIf="errorUserData != null" class="error-holder">
        {{ errorUserData }}
      </div>
      <div *ngIf="messageUserData != null" class="message-holder">
        {{ messageUserData }}
      </div>
      <button class="thin" type="submit">
        {{ chs.getText("megvaltoztatas") }}
      </button>
    </div>

    <div class="bottom-holder">
      <app-ac-menu></app-ac-menu>
    </div>
  </form>

  <div class="center-content">
    <button type="button" class="logout thin" (click)="logout()">
      {{ chs.getText("kilepes") }}
    </button>
  </div>

  <img class="bg-img" src="assets/background/gsm_settings_bg.svg" />
</div>
