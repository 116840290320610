import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, pipe } from 'rxjs';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-fan-settings',
  templateUrl: './fan-settings.component.html',
  styleUrls: ['./fan-settings.component.scss'],
})
export class FanSettingsComponent implements OnInit {
  private _acId: number;
  private _ac: any;

  constructor(
    public chs: CommonHandlerService,
    public adminService: AdminService,
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute
  ) {
    this.fanConfigForm = this._formBuilder.group({
      fanSpeed: [],
      fanType: [],
      fanStepValues: [[0, 0, 0, 0, 0]],
      serviceId: [0],
    });
  }

  public fanStepSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([
    0, 0, 0, 0, 0,
  ]);

  public fanConfigForm: FormGroup;

  public getFanStep(step: number) {
    return this.fanStepSubject.pipe(
      map((steps) => {
        return steps[step];
      })
    );
  }

  public get acMac() {
    return this._ac?.mac;
  }

  public getMaxFanStep(step: number) {
    return this.fanStepSubject.pipe(
      map((steps) => {
        let fanSteps = this.fanConfigForm.controls['fanSpeed'].value;
        if (fanSteps == 1) {
          return 99;
        } else if (fanSteps == 3) {
          return steps[+step + 2] ?? 99;
        } else if (fanSteps == 5) {
          return steps[+step + 1] ?? 99;
        }
      })
    );
  }

  public getMinFanStep(step: number) {
    return this.fanStepSubject.pipe(
      map((steps) => {
        let fanSteps = this.fanConfigForm.controls['fanSpeed'].value;
        if (fanSteps == 1) {
          return 1;
        } else if (fanSteps == 3) {
          return steps[+step - 2] ?? 1;
        } else if (fanSteps == 5) {
          return steps[+step - 1] ?? 1;
        }
      })
    );
  }

  public fanSpeedList = [
    { label: '1-spd', value: 1 },
    { label: '3-spd', value: 3 },
    { label: '5-spd', value: 5 },
  ];

  public fanTypeList = [
    { label: 'AC', value: 'AC' },
    { label: 'DC', value: 'DC' },
  ];

  ngOnInit(): void {
    this._acId = this._activatedRoute.snapshot.params['acId'];
    this.adminService.getAdminController(this._acId).subscribe((controller) => {
      this._ac = controller;
      this.fanConfigForm.controls['fanSpeed'].setValue(controller.fanSteps);
      this.fanConfigForm.controls['fanType'].setValue(controller.service?.type);
      this.fanConfigForm.controls['serviceId'].setValue(controller.service?.id);
      if (controller.service) {
        this.fanConfigForm.controls['fanStepValues'].setValue([
          controller.service?.speed_1,
          controller.service?.speed_2,
          controller.service?.speed_3,
          controller.service?.speed_4,
          controller.service?.speed_5,
        ]);

        this.fanStepSubject.next(
          this.fanConfigForm.controls['fanStepValues'].value
        );
      }
    });
  }

  checkFanStep(minStep: number) {
    return this.fanConfigForm.controls['fanSpeed'].value >= minStep;
  }

  onStepChange(newValue: Event, step: number) {
    let steps = this.fanConfigForm.controls['fanStepValues'].value;
    steps[step] = newValue;
    this.fanConfigForm.controls['fanStepValues'].setValue(steps);
    this.fanStepSubject.next(
      this.fanConfigForm.controls['fanStepValues'].value
    );
  }

  onSaveSettings() {
    let settings = {
      type: this.fanConfigForm.controls['fanType'].value,
      fanSteps: this.fanConfigForm.controls['fanSpeed'].value,
      speed_1: this.fanConfigForm.controls['fanStepValues'].value[0],
      speed_2: this.fanConfigForm.controls['fanStepValues'].value[1],
      speed_3: this.fanConfigForm.controls['fanStepValues'].value[2],
      speed_4: this.fanConfigForm.controls['fanStepValues'].value[3],
      speed_5: this.fanConfigForm.controls['fanStepValues'].value[4],
      service_id: this.fanConfigForm.controls['serviceId'].value,
    };

    this.adminService.setFanSettings(this._ac, settings).subscribe((result) => {
      if (result.isSuccess()) {
        alert('Settings saved successfully!');
      } else {
        alert('Invalid speed settings!');
      }
    });
  }
}
