<h2>{{ chs.getText("jelszo_visszaallitas") }}</h2>
<form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
  <div class="form-row">
    <label>{{ chs.getText("email") }}:</label>
    <input
      type="email"
      formControlName="email"
      [ngClass]="{
        'is-invalid': submitted && resetPasswordForm.controls.email.errors
      }"
    />
  </div>
  <div *ngIf="hasError('email')">
    <div
      class="input-error"
      *ngIf="resetPasswordForm.controls['email'].errors.email"
    >
      {{ chs.getText("err__email", true) }}
    </div>
  </div>

  <div class="form-row">
    <label>{{ chs.getText("jelszo") }}:</label>
    <input type="password" formControlName="password" />
  </div>
  <div *ngIf="hasError('password')">
    <div
      class="input-error"
      *ngIf="resetPasswordForm.controls['password'].errors.minlength"
    >
      {{ chs.getText("rovid_jelszo", true) }}
    </div>
  </div>

  <div class="form-row">
    <label>{{ chs.getText("jelszo_ismet") }}:</label>
    <input
      type="password"
      formControlName="passwordRe"
      (blur)="checkConfirm()"
    />
  </div>
  <div *ngIf="hasError('passwordRe')">
    <div
      class="input-error"
      *ngIf="resetPasswordForm.controls['passwordRe'].errors.minlength"
    >
      {{ chs.getText("rovid_jelszo", true) }}
    </div>
    <div
      class="input-error"
      *ngIf="resetPasswordForm.controls['passwordRe'].errors.mismatch"
    >
      {{ chs.getText("jelszo_megerosites_hibas", true) }}
    </div>
  </div>

  <div *ngIf="error != null" class="error-holder">
    {{ error }}
  </div>
  <div *ngIf="message != null" class="message-holder">
    {{ message }}
  </div>

  <div class="center-content">
    <button type="submit">{{ chs.getText("jelszo_megvaltoztatasa") }}</button>
  </div>
</form>
