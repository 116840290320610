<div class="page-wrapper">
  <div class="end-content">
    <app-lang-selector></app-lang-selector>
  </div>
  <h2>{{ chs.getText("register__kerjuk_regisztraljon") }}</h2>
  <form [formGroup]="registrationForm" (ngSubmit)="register()">
    <div class="form-row">
      <label>{{ chs.getText("email") }}:</label>
      <input type="email" formControlName="email" maxlength="255" />
    </div>
    <div *ngIf="hasError('email')">
      <div
        class="input-error"
        *ngIf="registrationForm.controls['email'].errors?.invalid"
      >
        {{ registrationForm.controls["email"].errors?.message }}
      </div>
    </div>
    <div class="form-row">
      <label>{{ chs.getText("jelszo") }}:</label>
      <input type="password" formControlName="password" />
    </div>
    <div *ngIf="hasError('password')">
      <div
        class="input-error"
        *ngIf="registrationForm.controls['password'].errors.minlength"
      >
        {{ chs.getText("rovid_jelszo", true) }}
      </div>
    </div>
    <div class="form-row">
      <label>{{ chs.getText("utonev") }}:</label>
      <input type="text" formControlName="firstName" maxlength="255" />
    </div>
    <div class="form-row">
      <label>{{ chs.getText("vezeteknev") }}:</label>
      <input type="text" formControlName="lastName" maxlength="255" />
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <div class="center-content">
      <button type="submit">{{ chs.getText("regisztracio") }}</button>
      <a class="btn thin" routerLink="/login">{{ chs.getText("vissza") }}</a>
    </div>
  </form>
  <a routerLink="/resend-activation" class="upper margin-top-20">{{
    chs.getText("aktivalo_mail_ujrakuldes")
  }}</a>

  <img class="bg-img" src="assets/background/register_bg.svg" />
</div>
