import { Component, OnInit } from '@angular/core';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  constructor(
    public chs: CommonHandlerService,
    public infoService: InfoService
  ) {}

  ngOnInit(): void {
    if (this.infoService.localAcs.length > 0) {
      let ac = this.infoService.localAcs.find(
        (ac) => ac.id == this.infoService.ac
      );
      if (ac != undefined) {
        this.infoService.connectToWs(ac['mac']);
      }
    }
  }

  onNameChange(e) {
    this.infoService.sendWSMessage('name', e.target.value);
    this.infoService.info.name = e.target.value;
    this.infoService.localAcChanged = true;
  }

  toggleFanAuto() {
    this.infoService.info['fanAutostop'] =
      this.infoService.info['fanAutostop'] == 1 ? 0 : 1;
    this.infoService.sendWSMessage(
      'fanAutostop',
      this.infoService.info.fanAutostop
    );
  }
}
