<div class="page-wrapper">
  <h1 [innerHTML]="chs.getText('email_ujrakuldes')"></h1>
  <form [formGroup]="resendForm" (ngSubmit)="resendEmail()">
    <div class="form-row">
      <label>{{ chs.getText("email") }}:</label>
      <input
        type="email"
        formControlName="email"
        [ngClass]="{
          'is-invalid': submitted && resendForm.controls.email.errors
        }"
      />
    </div>
    <div class="form-row">
      <label>{{ chs.getText("jelszo") }}:</label>
      <input
        type="password"
        formControlName="password"
        [ngClass]="{
          'is-invalid': submitted && resendForm.controls.password.errors
        }"
      />
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <div class="bottom-holder center-content">
      <button type="submit">{{ chs.getText("ujrakuld") }}</button>
      <a class="btn thin" routerLink="/register">{{ chs.getText("vissza") }}</a>
    </div>
  </form>
  <img class="bg-img" src="assets/background/register_bg.svg" />
</div>
