import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout/default-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiResponseInterceptor } from './core/services/api-call-service/api-response.interceptor';
import { AuthHeaderInterceptor } from './core/authentication/auth-header.interceptor';
import { AccessGuard } from './core/authentication/access.guard';
import { HomeComponent } from './pages/home/home.component';
import { RegistrationComponent } from './pages/registration/registration/registration.component';
import { LangSelectorComponent } from './components/lang-selector/lang-selector/lang-selector.component';
import { ForgottenPasswordComponent } from './pages/forgotten-password/forgotten-password.component';
import { ConfirmResetComponent } from './pages/confirm-reset/confirm-reset.component';
import { SuccessComponent } from './pages/success/success.component';
import { AccountComponent } from './pages/account/account.component';
import { AcUnitsComponent } from './pages/ac-units/ac-units.component';
import { NewAcUnitComponent } from './pages/new-ac-unit/new-ac-unit.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EmailVerifyComponent } from './pages/email-verify/email-verify.component';
import { ResendActivationComponent } from './pages/resend-activation/resend-activation.component';
import { DeleteAcUnitComponent } from './pages/delete-ac-unit/delete-ac-unit.component';
import { BoatsComponent } from './pages/boats/boats.component';
import { NewBoatComponent } from './pages/new-boat/new-boat.component';
import { DeleteBoatComponent } from './pages/delete-boat/delete-boat.component';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { DetailsComponent } from './pages/details/details.component';
import { AlertComponent } from './pages/alert/alert.component';
import { CommonHandlerService } from './core/services/common-handler/common-handler.service';
import { AcMenuComponent } from './components/ac-menu/ac-menu/ac-menu.component';
import { InfoService } from './core/services/info-service/info.service';

import { NgxSpinnerModule } from '@hardpool/ngx-spinner';
import { FirmwareComponent } from './pages/firmware/firmware.component';
import { SeriasComponent } from './pages/serials/serias/serias.component';
import { AdminComponent } from './pages/admin/admin.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomersComponent } from './pages/customers/customers.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AdminMenuComponent } from './pages/admin-menu/admin-menu.component';
import { DirectMessageComponent } from './pages/direct-message/direct-message.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { AcTimerComponent } from './pages/ac-timer/ac-timer.component';
import { NumSelectorComponent } from './components/num-selector/num-selector.component';
import { FanSettingsComponent } from './pages/fan-settings/fan-settings.component';
import { HelpComponent } from './pages/help/help.component';
import { MacPipe } from './core/pipes/mac.pipe';
import { AccessControlDirective } from './core/authentication/access.directive';
import { AdminUsers } from './pages/admin-users/admin-users.component';
import { AdminControlComponent } from './pages/admin-control/admin-control.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    LoginComponent,
    HomeComponent,
    RegistrationComponent,
    LangSelectorComponent,
    ForgottenPasswordComponent,
    ConfirmResetComponent,
    SuccessComponent,
    AccountComponent,
    AcUnitsComponent,
    NewAcUnitComponent,
    EmailVerifyComponent,
    ResendActivationComponent,
    DeleteAcUnitComponent,
    BoatsComponent,
    NewBoatComponent,
    DeleteBoatComponent,
    HomeMenuComponent,
    DetailsComponent,
    AlertComponent,
    AcMenuComponent,
    FirmwareComponent,
    SeriasComponent,
    AdminComponent,
    CustomersComponent,
    AdminMenuComponent,
    DirectMessageComponent,
    AcTimerComponent,
    NumSelectorComponent,
    FanSettingsComponent,
    HelpComponent,
    MacPipe,
    AccessControlDirective,
    AdminUsers,
    AdminControlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    DataTablesModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    NgJsonEditorModule,
  ],
  providers: [
    AccessGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true,
    },
    CommonHandlerService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [CommonHandlerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader2,
      deps: [InfoService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function ConfigLoader(chs: CommonHandlerService) {
  return () => chs.loadTexts(environment.textConfigFile);
}

export function ConfigLoader2(infoService: InfoService) {
  return () => infoService.loadSystemConfigs(environment.systemConfigFile);
}
