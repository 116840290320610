<a class="admin-home-link" routerLink="/admin/home">HOME</a>
<div class="wrapper">
  <h1>{{ chs.getText("admin_sw_update_header") }}</h1>

  <div class="holder">
    <label for="versions">{{ chs.getText("admin_sw_update_choose") }}</label>
    <select id="versions" [(ngModel)]="serial">
      <option value="A">A</option>
      <option value="B">B</option>
      <option value="C">C</option>
      <option value="D">D</option>
      <option value="E">E</option>
      <option value="F">F</option>
      <option value="G">G</option>
      <option value="H">H</option>
      <option value="I">I</option>
      <option value="J">J</option>
      <option value="K">K</option>
      <option value="L">L</option>
      <option value="M">M</option>
      <option value="N">N</option>
      <option value="O">O</option>
      <option value="P">P</option>
      <option value="Q">Q</option>
      <option value="R">R</option>
      <option value="S">S</option>
      <option value="T">T</option>
      <option value="U">U</option>
      <option value="V">V</option>
      <option value="W">W</option>
      <option value="X">X</option>
      <option value="Y">Y</option>
      <option value="Z">Z</option>
    </select>
  </div>

  <h2>
    {{ chs.getText("admin_sw_update_last_version") }}
    {{ getLastVersionOfSerial() }}
  </h2>

  <h2 class="margin-top-30">
    {{ chs.getText("admin_sw_update_new_version") }}
  </h2>
  <div class="holder">
    <div class="holder">
      <label for="versionNum"
        >{{ chs.getText("admin_sw_update_version_num") }}:</label
      >
      <input
        id="versionNum"
        type="text"
        [mask]="'999'"
        maxlength="3"
        [(ngModel)]="versionNum"
      />
    </div>

    <input
      #inputFile
      class="margin-top-20"
      type="file"
      id="file"
      (change)="handleFileInput($event.target.files)"
    />

    <button class="margin-top-20" (click)="upload()">
      {{ chs.getText("admin_sw_update_upload") }}
    </button>
  </div>
</div>
