import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { CONFIGS } from 'src/app/core/models/configs';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { ApiResponseInterceptor } from 'src/app/core/services/api-call-service/api-response.interceptor';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-boats',
  templateUrl: './boats.component.html',
  styleUrls: ['./boats.component.scss'],
})
export class BoatsComponent implements OnInit {
  boatList: Observable<any[]>;
  boats: any[] = [];
  selectedBoat: object = null;

  submitted = false;
  isEdit = false;
  error: string = null;

  editForm: FormGroup;
  boatId: number;

  @ViewChild('ngSelect') ngSelect: ElementRef;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private infoService: InfoService,
  ) {
    this.editForm = this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      port: [''],
      dock: [''],
      registration_number: [''],
    });
  }

  ngOnInit(): void {
    this.getBoatList();
  }

  getBoat(selection) {
    this.selectedBoat = this.boats.find((x) => x['id'] == selection['value']);
  }

  getBoatList(isEdit: boolean = false) {
    this.apiCallService
      .apiGet<ApiResponse>('/api/boats')
      .subscribe((response) => {
        if (response && response.isSuccess()) {
          let list = [];
          for (let i in response.data) {
            this.boats.push(response.data[i]);
            list.push({
              value: response.data[i]['id'],
              label: response.data[i]['name'],
            });
          }
          if (response.data.length > 0 && !isEdit) {
            this.editForm.get('id').setValue(response.data[0].id);
            this.selectedBoat = response.data[0];
          }
          this.boatList = of(list);
        }
      });
  }

  editBoat(edit = true) {
    if (edit && this.selectedBoat != null) {
      this.isEdit = true;
      this.editForm.get('name').setValue(this.selectedBoat['name']);
      this.editForm.get('port').setValue(this.selectedBoat['port']);
      this.editForm.get('dock').setValue(this.selectedBoat['dock']);
      this.editForm
        .get('registration_number')
        .setValue(this.selectedBoat['registration_number']);
    } else if (!edit) {
      this.isEdit = false;
    }
  }

  saveBoat() {
    this.submitted = true;
    if (this.editForm.invalid) {
      this.error = this.chs.getText('kotelezo_mezok', true);
      return;
    }

    const formData = new FormData();
    formData.append('name', this.editForm.get('name').value);
    formData.append('port', this.editForm.get('port').value);
    formData.append('dock', this.editForm.get('dock').value);
    formData.append(
      'registration_number',
      this.editForm.get('registration_number').value,
    );
    formData.append('boat_id', this.editForm.get('id').value);
    this.apiCallService
      .apiPut<ApiResponse>('/api/boats', formData)
      .subscribe((response) => {
        if (response && response.isSuccess()) {
          this.selectedBoat['name'] = this.editForm.get('name').value;
          this.selectedBoat['port'] = this.editForm.get('port').value;
          this.selectedBoat['dock'] = this.editForm.get('dock').value;
          this.selectedBoat['registration_number'] = this.editForm.get(
            'registration_number',
          ).value;
          this.editBoat(false);
          this.getBoatList(true);
        }
      });
  }
}
