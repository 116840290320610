import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss'],
})
export class ForgottenPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  error: string = null;
  message: string = null;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private router: Router,
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        },
      ],
    });
  }

  ngOnInit(): void {}

  resetPassword() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      if (this.resetPasswordForm.get('email').value.length < 1) {
        this.error = this.chs.getText('kotelezo_mezok', true);
      }
      return;
    }

    const formdata = new FormData();
    formdata.append('email', this.resetPasswordForm.get('email').value);
    formdata.append('url_fragment', 'confirm-reset');
    this.apiCallService
      .apiPost<ApiResponse>('/api/forgotten-password', formdata)
      .subscribe((result) => {
        if (result.isSuccess()) {
          this.error = null;
          this.router.navigate(['success'], {
            state: { data: 'password-reset' },
          });
        } else {
          this.error = this.chs.handleError(this.resetPasswordForm, result);
        }
      });
  }

  hasError(input: string): boolean {
    return (
      this.resetPasswordForm.controls[input].invalid &&
      (this.resetPasswordForm.controls[input].dirty ||
        this.resetPasswordForm.controls[input].touched)
    );
  }
}
