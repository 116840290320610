import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/core/models/customer';
import { Microcontroller } from 'src/app/core/models/microcontroller';
import { User } from 'src/app/core/models/users/user';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { CustomerService } from 'src/app/core/services/customer.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  public customers: Customer[];
  public selectedCustomer: Customer = null;
  public filter: string = '';
  public submitted: boolean = false;
  public controllers: Microcontroller[] = [];
  users: User[] = [];

  newMac: string = '';

  public editMode: boolean = false;
  public acForm: FormGroup;
  public customerForm: FormGroup;

  constructor(
    public chs: CommonHandlerService,
    public customerService: CustomerService,
    private adminService: AdminService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.customerForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      customer_id: [
        '',
        [Validators.required, Validators.min(0), Validators.max(255)],
      ],
    });

    this.acForm = this._formBuilder.group({
      acId: [null, Validators.required],
    });
  }

  getCustomerControllers() {
    return this.controllers.filter(
      (c) => c.customer_id === this.selectedCustomer.id
    );
  }

  getOwnerName(userId: number) {
    const user = this.users.find((u) => u.id === userId);
    return !!user ? `${user?.first_name} ${user?.last_name}` : '';
  }

  ngOnInit(): void {
    this.getCustomers();
    this.getControllers();
    this.getUsers();
  }

  onSubmit() {
    if (!!this.selectedCustomer) {
      this.updateCustomer();
    } else {
      this.createCustomer();
    }
  }

  createCustomer() {
    this.customerForm.markAllAsTouched();
    this.submitted = true;
    if (this.customerForm.valid) {
      this.customerService
        .newCustomer(
          this.customerForm.get('customer_id').value,
          this.customerForm.get('name').value
        )
        .subscribe((response) => {
          if (response.isSuccess()) {
            this.customerForm.reset();
            this.submitted = false;
            this.getCustomers();
          } else {
            alert('Unable to save customer!');
          }
        });
    }
  }

  getCustomers() {
    this.customerService.getCustomers().subscribe((customers) => {
      this.customers = customers;
      const selectedId = this.route.snapshot.params['id'];
      if (!!selectedId) {
        const customer = this.customers.find((c) => c.id == selectedId);
        this.editCustomer(customer);
      }
    });
  }

  getControllers() {
    this.adminService
      .getAdminControllers()
      .subscribe((controllers) => (this.controllers = controllers));
  }

  getUsers() {
    this.adminService.getUsers().subscribe((u) => (this.users = u));
  }

  deleteCustomer(event: any, customer: Customer) {
    event.preventDefault();
    event.stopPropagation();
    if (confirm(`Confirm deleting customer ${customer.name}`)) {
      this.customerService.deleteCustomer(customer.id).subscribe((response) => {
        if (response.isSuccess()) {
          this.getCustomers();
        } else {
          alert('Unable to delete customer!');
        }
      });
    }
  }

  editCustomer(customer: Customer) {
    this.selectedCustomer = customer;
    this.customerForm.get('name').setValue(customer.name);
    this.customerForm.get('customer_id').setValue(customer.customer_id);
  }

  resetEdit() {
    this.customerForm.reset();
    this.selectedCustomer = null;
  }

  updateCustomer() {
    const customer: Customer = {
      ...this.selectedCustomer,
      customer_id: this.customerForm.get('customer_id').value,
      name: this.customerForm.get('name').value,
    };
    this.customerService.updateCustomer(customer).subscribe((response) => {
      if (response.isSuccess()) {
        this.getCustomers();
        alert('Successful update');
      } else {
        alert('Unable to update customer!');
      }
    });
  }

  checkFilter(customer: Customer) {
    return (
      customer.name.toLowerCase().includes(this.filter.toLowerCase()) ||
      customer.customer_id
        .toString()
        .toLowerCase()
        .includes(this.filter.toLowerCase())
    );
  }

  addNewController() {
    const controller = this.controllers.find(
      (c) => c.mac === this.acForm.get('acId').value
    );
    if (!!controller && this.acForm.valid) {
      this.customerService
        .addControllerToCustomer(this.selectedCustomer.id, controller.id)
        .subscribe((response) => {
          if (response.isSuccess()) {
            this.getControllers();
            this.acForm.reset();
          } else {
            alert('Unable to add AC to customer');
          }
        });
    } else {
      alert('Please provide a valid AC ID!');
    }
  }
}
