<div class="page-wrapper">
  <div class="end-content">
    <a class="btn thin noborder margin-top-20 upper" routerLink="/home"
      ><img class="margin-right-8" src="assets/icons/house.svg" />
      {{ chs.getText("fooldal") }}</a
    >
  </div>

  <div class="center-content">
    <h1 class="upper margin-top-30">{{ chs.getText("hajok") }}</h1>
  </div>

  <form class="margin-top-30" [formGroup]="editForm" (ngSubmit)="saveBoat()">
    <div class="form-row">
      <ng-select
        #ngSelect
        *ngIf="!isEdit"
        formControlName="id"
        appearance="outline"
        (change)="getBoat($event)"
        [searchable]="false"
        [items]="boatList | async"
        bindValue="value"
      >
      </ng-select>
      <input
        *ngIf="selectedBoat != null && isEdit"
        type="text"
        formControlName="name"
        class="fw"
        maxlength="255"
      />
    </div>
    <div class="form-row margin-top-30">
      <label>{{ chs.getText("kikoto") }}:</label>
      <div *ngIf="selectedBoat != null && !isEdit">
        {{ selectedBoat["port"] }}
      </div>
      <input
        *ngIf="selectedBoat != null && isEdit"
        type="text"
        formControlName="port"
        maxlength="255"
      />
    </div>
    <div class="form-row">
      <label>{{ chs.getText("dokk") }}:</label>
      <div *ngIf="selectedBoat != null && !isEdit">
        {{ selectedBoat["dock"] }}
      </div>
      <input
        *ngIf="selectedBoat != null && isEdit"
        type="text"
        formControlName="dock"
        maxlength="255"
      />
    </div>
    <div class="form-row">
      <label>{{ chs.getText("lajstromszam") }}:</label>
      <div *ngIf="selectedBoat != null && !isEdit">
        {{ selectedBoat["registration_number"] }}
      </div>
      <input
        *ngIf="selectedBoat != null && isEdit"
        type="text"
        formControlName="registration_number"
        maxlength="255"
      />
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <div
      *ngIf="selectedBoat != null && isEdit"
      class="space-content margin-top-30"
    >
      <button type="submit" class="thin btn-center btn-100">
        {{ chs.getText("mentes") }}
      </button>
      <button class="thin btn-center btn-100" (click)="editBoat(false)">
        {{ chs.getText("vissza") }}
      </button>
    </div>
  </form>

  <div *ngIf="!isEdit" class="holder margin-top-50">
    <a class="btn thin btn-center btn-100" routerLink="/new-boat">{{
      chs.getText("uj")
    }}</a>
    <button class="btn thin btn-center btn-100" (click)="editBoat()">
      {{ chs.getText("szerkesztes") }}
    </button>
    <a class="btn thin btn-center btn-100" routerLink="/delete-boat">{{
      chs.getText("torles")
    }}</a>
  </div>

  <div class="bottom-holder">
    <app-ac-menu></app-ac-menu>
  </div>

  <img class="bg-img" src="assets/background/boats_bg.svg" />
</div>
