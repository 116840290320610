<div
  class="container page-wrapper margin-top-50"
  [ngClass]="{ 'error-mode': !infoService.connected }"
>
  <div class="row">
    <div>{{ chs.getText("visszatero_homerseklet") }}</div>
    <div class="upper">
      <span
        >{{ infoService.returnC }}
        {{ infoService.info.returnC == "--" ? "" : infoService.cF }}</span
      >
    </div>
  </div>
  <div class="row">
    <div>{{ chs.getText("parologtato_homerseklet") }}</div>
    <div class="upper">
      <span
        >{{ infoService.evapC }}
        {{ infoService.info.evapC == "--" ? "" : infoService.cF }}</span
      >
    </div>
  </div>
  <div class="row">
    <div>{{ chs.getText("hutoviz_homerseklet") }}</div>
    <div class="upper">
      <span
        >{{ infoService.coolingC }}
        {{ infoService.info.coolingC == "--" ? "" : infoService.cF }}</span
      >
    </div>
  </div>
  <div class="row">
    <div>{{ chs.getText("aramerosseg") }}</div>
    <div class="upper">
      <span>{{ !infoService.current ? "" : infoService.current }}</span>
    </div>
  </div>

  <hr />

  <div class="flex">
    <div class="inline-block col-top">
      <h2 class="mac_id">{{ infoService.mac }}</h2>
      <!--<div class="serial">{{chs.getText('mbc_serial') }} {{infoService.info.serial.length > 0 ? infoService.info.serial : 'N/A' }}</div>-->
      <div class="serial">
        {{ chs.getText("verzio")
        }}{{
          infoService.info.SWversion.length > 0
            ? infoService.info.SWversion
            : "N/A"
        }}
      </div>
      <div class="serial mb-28">
        {{ chs.getText("nx_verzio")
        }}{{
          infoService.info.NXversion.length > 0
            ? infoService.info.NXversion
            : "N/A"
        }}
      </div>
      <a
        routerLink="/timer"
        class="btn thin w-100 fs-16"
        *ngIf="infoService.connected"
        >{{ chs.getText("timer_settings") }}</a
      >

      <a routerLink="/help" class="btn sleek-btn thin w-100 fs-16">{{
        chs.getText("segitseg")
      }}</a>
    </div>
    <div class="inline-block col-top">
      <input
        class="ac-name"
        *ngIf="infoService.connected"
        [(value)]="infoService.info.name"
        type="text"
        (change)="onNameChange($event)"
      />
      <button
        *ngIf="infoService.connected"
        class="thin sleek-btn w-100"
        (click)="infoService.changeCF()"
      >
        {{
          infoService.cF.toUpperCase() == "C"
            ? chs.getText("celsius")
            : chs.getText("farenheit")
        }}
      </button>
      <button
        *ngIf="infoService.connected"
        type="button"
        class="thin onoff sleek-btn w-100"
        (click)="infoService.toggleLangs(chs.langsRaw)"
      >
        <span class="upper">{{ infoService.lang }}</span>
      </button>
      <button
        *ngIf="infoService.connected"
        type="button"
        class="thin onoff sleek-btn w-100"
        (click)="toggleFanAuto()"
      >
        <span class="upper">{{
          infoService.info.fanAutostop == 1
            ? chs.getText("autostop_be")
            : chs.getText("autostop_ki")
        }}</span>
      </button>
    </div>
  </div>
  <div class="pl-2 mb-3 serial w-100 red" *ngIf="!infoService.connected">
    {{ chs.getText("utolso_aktivitas") }}{{ infoService.lastActivity }}
  </div>
</div>

<div class="bottom-holder">
  <app-home-menu
    [errorMode]="!infoService.connected"
    [altMode]="true"
  ></app-home-menu>
</div>

<img class="bg-img" src="assets/background/details_bg.svg" />
