import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-new-ac-unit',
  templateUrl: './new-ac-unit.component.html',
  styleUrls: ['./new-ac-unit.component.scss'],
})
export class NewAcUnitComponent implements OnInit {
  acForm: FormGroup;
  submitted = false;
  error = null;
  message = null;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private infoService: InfoService,
  ) {
    this.acForm = this.formBuilder.group({
      acId: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;
    if (this.acForm.invalid) {
      if (
        this.acForm.get('acId').value == null ||
        this.acForm.get('acId').value.length == 0
      ) {
        this.error = this.chs.getText('kotelezo_mezok', true);
      } else if (this.acForm.get('acId').value.length < 12) {
        this.error = this.chs.getText('legalabb_12_karakter', true);
      }
      return;
    }

    this.error = null;
    this.message = this.chs.getText('kapcsolodas', false);

    let unitId = this.acForm.get('acId').value;

    const formData = new FormData();
    formData.append('mac', unitId);
    this.apiCallService
      .apiPut<ApiResponse>('/api/microcontrollers/connect', formData)
      .subscribe((result) => {
        if (result && result.isSuccess()) {
          this.message = this.chs.getText('sikeres_ac_felvetel', false);
          this.infoService.localAcChanged = true;
          this.infoService.ac;
        } else {
          this.message = this.chs.getText('sikertelen_hivas', true);
          this.error = this.chs.handleError(this.acForm, result);
        }
      });
  }
}
