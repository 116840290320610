<div class="page-wrapper">
  <h2>{{ chs.getText("uj_ac_egyseg_hozzaadasa") }}</h2>

  <form class="margin-top-50" [formGroup]="acForm">
    <div class="form-row">
      <label class="upper">{{ chs.getText("egyseg_id") }}:</label>
      <input
        type="text"
        class="upper"
        [mask]="'AA-AA-AA-AA-AA-AA'"
        placeholder="AA-AA-AA-AA-AA-AA"
        formControlName="acId"
        [ngClass]="{ 'is-invalid': submitted && acForm.controls.acId.errors }"
      />
    </div>

    <div class="center-content">
      <button class="margin-top-20 thin" (click)="onSubmit()">
        {{ chs.getText("hozzaadas") }}
      </button>
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <hr />

    <div class="float-message" *ngIf="message != null">
      {{ message }}
    </div>
  </form>

  <div class="bottom-holder">
    <div class="center-content">
      <a class="btn thin" routerLink="/ac-units">{{ chs.getText("vissza") }}</a>
    </div>
  </div>
  <img class="bg-img" src="assets/background/register_bg.svg" />
</div>
