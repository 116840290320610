import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { WSClient } from 'src/app/core/models/websocket';
import { isThisTypeNode } from 'typescript';

@Component({
  selector: 'app-direct-message',
  templateUrl: './direct-message.component.html',
  styleUrls: ['./direct-message.component.scss'],
})
export class DirectMessageComponent implements OnInit {
  public mac: string = null;
  public json: string = '';
  private _websocket: WSClient = new WSClient();

  public sampleJSON: string[] = [
    '// 0=none, 1=MBC, 2=WIA, 3=Mastertec, 4=Dufour, 5=Fountaine, 6=Aventura, 7=Xquisite',
    '{"Id":"C8F09EB404E0","customer":"1"}',
    '{"Id":"C8F09EB404E0","com":"factoryreset"}',
    '{"Id":"C8F09EB404E0","com":"reset"}',
    '{"Id":"C8F09EB404E0","com":"fwupdate"}',
    '// ON-OFF 1=on, 0=off',
    '{"Id":"C8F09EB404E0","onoff":"1"}',
    '// MODE 1=cool 3=heat 4=fan 5=auto',
    '{"Id":"C8F09EB404E0","mode":"1"}',
    '// SET TEMP= Celsiusx2+80, min=16C, max=30C',
    '{"Id":"C8F09EB404E0","setTemperature":"120"}',
    '// FAN SPEED=  1..5, 6=AUTO',
    '{"Id":"C8F09EB404E0","fanSpeed":"1"}',
    '// FAN AUTO STOP 1=on, 0=off',
    '{"Id":"C8F09EB404E0","fanAutostop":"1"}',
    '// AC OWNED 1=true, 0=false',
    '{"Id":"C8F09EB404E0","acOwned":"1"}',
    '// AC NAME',
    '{"Id":"C8F09EB404E0","acName":"salon"}',
    '// NEW SW VERSION',
    '{"Id":"C8F09EB404E0","new_sw_version":"A060"}',
    '// NEW NX VERSION',
    '{"Id":"C8F09EB404E0","new_nx_version":"T028"}',
  ];

  constructor(private _activatedRoute: ActivatedRoute) {
    this.mac = this._activatedRoute.snapshot.params['mac'];
    if (this.mac) {
      this._websocket.connect(this.mac);
      this._websocket.socket.subscribe();
    }
  }

  ngOnInit(): void {}

  prettyPrint() {
    if (isJsonString(this.json)) {
      var obj = JSON.parse(this.json);
      var pretty = JSON.stringify(obj, undefined, 4);
      this.json = pretty;
    }
  }

  send() {
    if (isJsonString(this.json)) {
      this._websocket.sendDirectMessage(this.json);
      alert('Message sent!');
    } else {
      alert('Invalid json!');
    }
  }
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
