import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  type: string = 'default';

  constructor(
    public chs: CommonHandlerService,
    private router: Router,
  ) {
    let nav = this.router.getCurrentNavigation();
    this.type =
      nav != null
        ? this.router.getCurrentNavigation().extras.state['data']
        : 'default';
  }

  ngOnInit(): void {}
}
