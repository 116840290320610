import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-new-boat',
  templateUrl: './new-boat.component.html',
  styleUrls: ['./new-boat.component.scss'],
})
export class NewBoatComponent implements OnInit {
  submitted = false;
  confirm = false;
  error: string = null;
  message: string = null;
  newForm: FormGroup;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
  ) {
    this.newForm = this.formBuilder.group({
      name: ['', Validators.required],
      port: [''],
      dock: [''],
      registration_number: [''],
    });
  }

  ngOnInit(): void {}

  initAdd(init = true) {
    this.confirm = init;
    if (!init) {
      this.newForm.get('name').setValue('');
      this.newForm.get('name').setErrors(null);
      this.newForm.get('port').setValue('');
      this.newForm.get('port').setErrors(null);
      this.newForm.get('dock').setValue('');
      this.newForm.get('dock').setErrors(null);
      this.newForm.get('registration_number').setValue('');
      this.newForm.get('registration_number').setErrors(null);
      this.error = null;
      this.newForm.enable();
      return;
    }

    this.submitted = true;
    if (this.newForm.invalid) {
      this.error = this.chs.getText('kotelezo_mezok', true);
      this.confirm = false;
      return;
    }

    this.newForm.disable();
  }

  addBoat() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.error = this.chs.getText('kotelezo_mezok', true);
      return;
    }

    this.newForm.enable();

    const formData = new FormData();
    formData.append('name', this.newForm.get('name').value);
    formData.append('port', this.newForm.get('port').value);
    formData.append('dock', this.newForm.get('dock').value);
    formData.append(
      'registration_number',
      this.newForm.get('registration_number').value,
    );
    this.apiCallService
      .apiPost<ApiResponse>('/api/boats', formData)
      .subscribe((response) => {
        if (response && response.isSuccess()) {
          this.error = null;
          this.message = this.chs.getText('sikeres_hajo_rogzites');
          this.confirm = false;

          this.newForm.get('name').setValue('');
          this.newForm.get('name').setErrors(null);
          this.newForm.get('port').setValue('');
          this.newForm.get('port').setErrors(null);
          this.newForm.get('dock').setValue('');
          this.newForm.get('dock').setErrors(null);
          this.newForm.get('registration_number').setValue('');
          this.newForm.get('registration_number').setErrors(null);
        } else {
          this.confirm = false;
          this.error = this.chs.handleError(this.newForm, response);
        }
      });
  }

  hasError(input: string) {
    return this.newForm.get(input).hasError;
  }
}
