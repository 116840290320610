import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call-service/api-call-service.service';
import { ApiResponse } from '../models/api-response/api-response';
import { map } from 'rxjs/operators';
import { Customer } from '../models/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apiCallService: ApiCallService) {}

  getCustomers() {
    return this.apiCallService.apiGet<ApiResponse>('/api/customers').pipe(
      map((response) => {
        return response.data || [];
      })
    );
  }

  newCustomer(customerId: string, name: string) {
    const formData = new FormData();
    formData.append('customer_id', customerId);
    formData.append('name', name);

    return this.apiCallService.apiPost<ApiResponse>('/api/customers', formData);
  }

  deleteCustomer(id: number) {
    const formData = new FormData();
    formData.append('id', id.toString());

    return this.apiCallService.apiDelete<ApiResponse>(
      '/api/customers',
      formData
    );
  }

  updateCustomer(customer: Customer) {
    const formData = new FormData();
    formData.append('id', customer.id.toString());
    formData.append('customer_id', customer.customer_id.toString());
    formData.append('name', customer.name);

    return this.apiCallService.apiPut<ApiResponse>('/api/customers', formData);
  }

  addControllerToCustomer(id: number, controllerId: number) {
    const formData = new FormData();
    formData.append('microcontroller_id', controllerId.toString());
    formData.append('customer_id', id.toString());

    return this.apiCallService.apiPut<ApiResponse>(
      '/api/admin-microcontrollers',
      formData
    );
  }
}
