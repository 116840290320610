import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss'],
})
export class EmailVerifyComponent implements OnInit {
  id: number;
  params: string;

  confirmed = '-1';

  constructor(
    public chs: CommonHandlerService,
    private activatedRoute: ActivatedRoute,
    private apiCallService: ApiCallService,
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id;
    this.params = this.activatedRoute.snapshot.params.params;

    if (this.id != undefined && this.params != undefined) {
      this.apiCallService
        .apiGet<ApiResponse>('/api/email/verify/' + this.id + '/' + this.params)
        .subscribe((apiResponse) => {
          this.confirmed = apiResponse && apiResponse.isSuccess() ? '1' : '0';
        });
    }
  }
}
