import { EventEmitter, Injectable } from '@angular/core';
import { ApiResponse } from '../../models/api-response/api-response';
import {
  AC_TYPES,
  FAN_STEPS,
  MAIN_INFO,
  MAIN_INFO_NAMES,
} from '../../models/configs';
import { ApiCallService } from '../api-call-service/api-call-service.service';
import { CommonHandlerService } from '../common-handler/common-handler.service';
import * as json_configs from '../../../../assets/configs.json';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { WSClient } from '../../models/websocket';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  public localAcChanged: boolean = true;
  public localAcs = [];

  private _websocket = null;

  acId: number = null;
  acMac: string = null;
  public info: any;
  public lastVersions: any;
  public lostConnection: boolean;

  public systemConfigs: object;

  public localTemp = null;
  public localFan = null;
  public localCF = null;
  public localOnOff = null;
  public localMode = null;
  public localAcLang = null;
  public localEcoSetting = null;

  sendBuffer = [];
  sendBuffer2 = [];
  sendTime = 5000;
  sendInterval = null;
  sendTimeout = null;
  sendTimer = null;
  coordTimer = null;

  refreshTime = null;
  refreshTimer = null;

  spinnerVisible: boolean = false;

  public nameChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private apiCallService: ApiCallService,
    private chs: CommonHandlerService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.systemConfigs = (json_configs as any).default;
    /*Ha valami hiba lenne, akkor az alábbi placeholder adatok jelennek meg a felületen*/
    this.info = {
      microcontroller_id: null,
      SWversion: '',
      NXversion: '',
      name: '',
      onoff: 0,
      mode: 1,
      setTemperature: 'N/A',
      fanSpeed: 6,
      fanAutostop: 0,
      fanSteps: 6,
      temperature: 'N/A',
      isError: 0,
      cF: 'C',
      comp: 0,
      heat: 0,
      valve: 0,
      pump: 0,
      returnC: '--',
      evapC: '--',
      coolingC: '--',
      currentA: '--',
      mystery: 'N/A',
      error_0: 0,
      error_1: 0,
      error_2: 0,
      error_3: 0,
      error_4: 0,
      error_5: 0,
      error_6: 0,
      error_7: 0,
      error_8: 0,
      error_9: 0,
      error_10: 0,
      error_11: 0,
      error_12: 0,
      error_13: 0,
      error_14: 0,
      error_15: 0,
      fanType: 'AC',
      language: 'EN',
      temperatureScale: 'c',
      localIp: null,
      lastActivity: '',
      serial: '',
      ora_on: '0',
      perc_on: '0',
      ora_off: '0',
      perc_off: '0',
      kikapcs: '0',
      bekapcs: '0',
      ecoMode: '0',
      uzemmod: '1',
    };
    this.lastVersions = {};
    this.lostConnection = false;

    //ITT!
  }

  //*Config adatok lekérdezés
  loadSystemConfigs(url) {
    return this.httpClient
      .get(url)
      .pipe(
        mergeMap((res) => {
          this.systemConfigs = (res as ApiResponse).data;
          return of(this.systemConfigs);
        })
      )
      .subscribe();
  }

  //Csatlakozva- van-e a kiválasztott AC a szerverhez, vagy sem
  public get connected() {
    return !this.lostConnection;
  }

  //ÉL-e még a websocket kapcoslat a böngésző és a szerver között
  public get wsConnected() {
    return this._websocket.connected;
  }

  public get ac() {
    return this.acId;
  }

  public get mac() {
    let mac = null;
    if (this.acMac == null) {
      mac = this.localAcs.find((ac) => ac['id'] == this.acId)['mac'];
      mac = mac == undefined ? null : mac.match(/.{2}/g).join('-');
    } else {
      mac = this.acMac.match(/.{2}/g).join('-');
    }
    return mac;
  }

  public set ac(id: number) {
    if (id != null) {
      localStorage.setItem(environment.localAcInd, id.toString());
    }
    this.acId = id;
  }

  public get onoff() {
    return this.localOnOff != null ? this.localOnOff : this.info['onoff'];
  }

  public get setTemp() {
    return this.localTemp != null ? this.getTemp(this.localTemp) : 'N/A';
  }

  public get realTemp() {
    return this.getTemp(this.info['temperature']);
  }

  public get evapC() {
    return this.getTemp(this.info['evapC']);
  }

  public get returnC() {
    return this.getTemp(this.info['returnC']);
  }

  public get coolingC() {
    return this.getTemp(this.info['coolingC']);
  }

  public get fan() {
    return this.localFan != null ? this.localFan : this.info['fanSpeed'];
  }

  public get fanAutoOn() {
    return this.info['fanAutostop'] == '1';
  }

  public get hasError() {
    let hasError = false;
    for (let i = 1; i < 13; i++) {
      if (this.info['error_' + i] == 1) {
        hasError = true;
      }
    }
    return hasError;
  }

  public get returnTemp() {
    let temp = this.getTemp(this.info['returnC']);
    return temp == '--' ? temp : temp + this.cF;
  }

  public get evaporatorTemp() {
    let temp = this.getTemp(this.info['evapC']);
    return temp == '--' ? temp : temp + this.cF;
  }

  public get coolingWaterTemp() {
    let temp = this.getTemp(this.info['coolingC']);
    return temp == '--' ? temp : temp + this.cF;
  }

  public get cF() {
    return this.localCF == null
      ? this.info == undefined
        ? 'C'
        : this.info['temperatureScale']
      : this.localCF;
  }

  public get mode() {
    return this.localMode == null ? this.info['mode'] : this.localMode;
  }

  public get ecoMode() {
    return this.info['ecoMode'] == '1' || false;
  }

  public get ecoSetting() {
    return this.localEcoSetting == null
      ? this.info['uzemmod']
      : this.localEcoSetting;
  }

  public get lang() {
    return this.localAcLang == null ? this.info['language'] : this.localAcLang;
  }

  public get lastActivity() {
    return this.info.lastActivity.length == 0 ? 'N/A' : this.info.lastActivity;
  }

  public get current() {
    if (this.info) {
      let displayCurrent = this.info['currentA']
        ? this.info['currentA'] / 10
        : null;
      return displayCurrent && displayCurrent >= 0
        ? displayCurrent + 'A'
        : '--';
    } else {
      return '--';
    }
  }

  public get lastSwVersion() {
    let serial =
      this.info['SWversion'] != null && this.info['SWversion'].length > 0
        ? this.info['SWversion'][0]
        : null;
    if (serial != null) {
      let serialLastVersion = this.lastVersions[serial];
      if (serialLastVersion && serialLastVersion != null) {
        return serial + serialLastVersion;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public get isTempMax() {
    return this.cF.toUpperCase() == 'C'
      ? this.setTemp == parseInt(json_configs.maxTemp)
      : this.setTemp == this.C2F(parseInt(json_configs.maxTemp));
  }

  public get isTempMin() {
    return this.cF.toUpperCase() == 'C'
      ? this.setTemp == parseInt(json_configs.minTemp)
      : this.setTemp == this.C2F(parseInt(json_configs.minTemp));
  }

  public get lastWebVersionDate() {
    return this.systemConfigs['lastWebVersionDate'];
  }

  public get lastApiVersionDate() {
    return this.systemConfigs['lastApiVersionDate'];
  }

  public get ACType() {
    return;
  }

  /*Hibák listája*/
  public getErrorList(acType: AC_TYPES) {
    var list = [];
    for (let i = 0; i < 16; i++) {
      if (this.info['error_' + i] == 1) {
        let errorTxt = this.chs.getText('error_' + i, true);
        if (acType == AC_TYPES.DC) {
          errorTxt = this.chs.getText('error_' + i + '_dc', true);
        }
        list.push({
          index: i,
          text: i + ': ' + errorTxt,
        });
      }
    }
    return list;
  }

  /*WEBSOCKET KAPCSOLAT SÉ ÜZENETKÜÖLDÉS*/
  /*------------------------------------------------------------------------------------------------*/
  //Csatlakozás a websockethez (böngésző és szerver között)
  public connectToWs(acMac: string, customerId: any = null) {
    if (this._websocket == null) {
      this._websocket = new WSClient();
      this._websocket.socket.subscribe(
        (wsResponse) => {
          this._handleWSMessages(wsResponse);
        },
        (error) => {
          console.error('WS ERROR', error);
          this.lostConnection = true;
        }
      );
    }

    if (!this._websocket.checkMac(acMac)) {
      this._websocket.connect(acMac, customerId);
    }
  }

  //Oldal újratöltéskor ismét csatlakozunk a ws-hez
  public reconnectToWs(mac: any) {
    location.reload();
  }

  //Egyszerű üzenetküldés kulcs-érték pár alapján
  //Használja:
  //  - névváltoztatás
  public sendWSMessage(key: string, value: string) {
    this._websocket.set(key, value);
  }

  /*Működés:
  1. A browser felcsatlakozik a szerverre:
    -> ha a kontroller nincs csatlakozva, akkor a szerver <acNotConn>-t üzen vissza
  2. A kontroller az alábbi üzeneteket küldheti
    -> <acSet>: itt minden adatát küldi, és a browser ezekkel felülírja a lokális értékeket
    -> <acMod>: itt módosíthatók a nem-Main adatok
    -> <acMain>: itt módosíthatók a Main adatok. Egy ilyen üzenetre a browser mindig válaszol egy <browserVer> üzenettel
    -> <acVer>: ezt a kontroller azután küldi, hogy megkapott egy <browserMain> üzenetet
  */
  private _handleWSMessages(wsResponse) {
    let msg = wsResponse.data;
    console.log(
      '%c<<GOT MSG>> ' + msg.messageType,
      'color: green; font-weight: bold'
    );
    console.log(msg);
    if (msg.messageType == undefined) {
      console.error('<<INVALID MESSAGE TYPE>>');
      return;
    }
    //Ha nincs kapcsolat az AC-val
    if (
      msg.messageType == 'acNotCon' ||
      msg.messageType == 'acDisconnect' ||
      msg.messageType == 'acDiscon'
    ) {
      this.lostConnection = true;
      this.stopSending();
      this.info = {
        microcontroller_id: null,
        SWversion: msg.SWversion != undefined ? msg.SWversion : 'N/A',
        NXversion: msg.NXversion != undefined ? msg.NXversion : 'N/A',
        name: '',
        onoff: 0,
        mode: 1,
        setTemperature: 'N/A',
        fanSpeed: 6,
        fanSteps: msg.fanSteps != undefined ? msg.fanSteps : null,
        fanAutostop: 0,
        '0': 6,
        temperature: 'N/A',
        isError: 0,
        cF: 'C',
        comp: 0,
        heat: 0,
        valve: 0,
        pump: 0,
        returnC: '--',
        evapC: '--',
        coolingC: '--',
        currentA: '--',
        mystery: 'N/A',
        error_0: 0,
        error_1: 0,
        error_2: 0,
        error_3: 0,
        error_4: 0,
        error_5: 0,
        error_6: 0,
        error_7: 0,
        error_8: 0,
        error_9: 0,
        error_10: 0,
        error_11: 0,
        error_12: 0,
        error_13: 0,
        error_14: 0,
        error_15: 0,
        fanType: 'AC',
        language: msg.language != undefined ? msg.language : 'EN',
        temperatureScale:
          msg.temperatureScale != undefined ? msg.temperatureScale : 'c',
        localIp: null,
        lastActivity: msg.lastActivity != undefined ? msg.lastActivity : '',
        serial: msg.serial != undefined ? msg.serial : 'N/A',
        ora_on: msg.ora_on != undefined ? msg.ora_on : '0',
        perc_on: msg.perc_on != undefined ? msg.perc_on : '0',
        ora_off: msg.ora_off != undefined ? msg.ora_off : '0',
        perc_off: msg.perc_off != undefined ? msg.perc_off : '0',
        kikapcs: msg.kikapcs != undefined ? msg.kikapcs : '0',
        bekapcs: msg.bekapcs != undefined ? msg.bekapcs : '0',
        ecoMode: msg.ecoMode != undefined ? msg.ecoMode : '0',
        uzemmod: msg.uzemmod != undefined ? msg.uzemmod : '0',
        service: null,
      };
      //ITT!
    }
    //Ha tényleges üzenet jött az AC-tól VAGY a másik böngészőtől
    else if (
      msg.messageType == 'acMod' ||
      msg.messageType == 'browserCon' ||
      msg.messageType == 'acSet' ||
      msg.messageType == 'browserMain'
    ) {
      let data = msg.acData;

      if (msg.messageType == 'acSet' || msg.messageType == 'browserCon') {
        this.lostConnection = false;
        this.info = data;
        this.localTemp = this.info['setTemperature'];
        this.localFan = this.info['fanSpeed'];
        this.localMode = this.info['mode'];
        this.localEcoSetting = this.info['uzemmod'];
        this.localOnOff = this.info['onoff'];
      } else if (
        msg.messageType == 'acMod' ||
        msg.messageType == 'browserMod'
      ) {
        this.lostConnection = false;
        let change = data['name'] != this.info['name'];
        this.info['SWversion'] =
          data.SWversion != undefined ? data.SWversion : 'N/A';
        this.info['NXversion'] =
          data.NXversion != undefined ? data.NXversion : 'N/A';
        this.info['name'] = data.name;
        this.info['fanSteps'] = data.fanSteps;
        this.info['fanAutostop'] = data.fanAutostop;
        this.info['temperature'] = data.temperature;
        this.info['comp'] = +data.comp;
        this.info['heat'] = +data.heat;
        this.info['valve'] = +data.valve;
        this.info['pump'] = +data.pump;
        this.info['returnC'] = +data.returnC;
        this.info['evapC'] = +data.evapC;
        this.info['coolingC'] = +data.coolingC;
        this.info['currentA'] = +data.currentA;
        this.info['error_0'] = data['error_0'];
        this.info['error_1'] = data['error_1'];
        this.info['error_2'] = data['error_2'];
        this.info['error_3'] = data['error_3'];
        this.info['error_4'] = data['error_4'];
        this.info['error_5'] = data['error_5'];
        this.info['error_6'] = data['error_6'];
        this.info['error_7'] = data['error_7'];
        this.info['error_8'] = data['error_8'];
        this.info['error_9'] = data['error_9'];
        this.info['error_10'] = data['error_10'];
        this.info['error_11'] = data['error_11'];
        this.info['error_12'] = data['error_12'];
        this.info['error_13'] = data['error_13'];
        this.info['error_14'] = data['error_14'];
        this.info['error_15'] = data['error_15'];
        this.info['fanType'] = data['fanType'];
        this.info['serial'] = data.serial;
        this.info['ora_on'] = data.ora_on;
        this.info['perc_on'] = data.perc_on;
        this.info['ora_off'] = data.ora_off;
        this.info['perc_off'] = data.perc_off;
        this.info['kikapcs'] = data.kikapcs;
        this.info['bekapcs'] = data.bekapcs;
        this.info['ecoMode'] = data.ecoMode;
        this.info['uzemmod'] = data.uzemmod;
        //ITT!

        //Hogy a component érzékelje a modell változást
        let tmp = this.info;
        this.info = tmp;

        if (change) {
          this.nameChange.emit(data['name']);
        }
      }
      //Browserek közti szinkronizáció
      else if (msg.messageType == 'browserMain') {
        this.localTemp = data['setTemperature'];
        this.localFan = data['fanSpeed'];
        this.localMode = data['mode'];
        this.localEcoSetting = data['uzemmod'];
        this.localOnOff = data['onoff'];
      }
    } else if (msg.messageType == 'acMain') {
      this.lostConnection = false;
      let data = msg.acData;
      let mainDataChange = !this.checkStates(data);
      this.localTemp = data['setTemperature'];
      this.localFan = data['fanSpeed'];
      this.localMode = data['mode'];
      this.localEcoSetting = data['uzemmod'];
      this.localOnOff = data['onoff'];

      if (mainDataChange) {
        console.log('%c<<SEND VERIFY>>', 'color: orange; font-weight: bold;');
        this._websocket.sendState({
          onoff: this.localOnOff,
          mode: this.localMode,
          setTemperature: this.localTemp,
          fanSpeed: this.localFan,
          uzemmod: this.localEcoSetting,
        });
      }
    } else if (msg.messageType == 'acVer') {
      this.lostConnection = false;
      if (this.checkStates(msg.payload)) {
        this.stopSending();
      }
    }
  }

  //Üzenet küldése az AC-nek a szerveren keresztül
  beginSending(info: MAIN_INFO) {
    if (this.sendInterval == null) {
      console.log('%c<<BEGIN SEND>>', 'color: blue; font-weight: bold');

      this.sendMessage();

      let timeout = 20000;
      //setTimeout(function() {}.bind(this), this.sendTime);
      this.sendInterval = setInterval(
        this.sendMessage.bind(this),
        this.sendTime
      );
      this.sendTimeout = setTimeout(
        function () {
          this.stopSending();
        }.bind(this),
        timeout
      );
    }
  }

  sendMessage() {
    console.log('%c<<SENDING>>', 'color: blue;');
    let msgBody = {};

    msgBody['setTemperature'] = this.localTemp;
    msgBody['fanSpeed'] = this.localFan;
    msgBody['mode'] = this.localMode;
    msgBody['onoff'] = this.localOnOff;
    msgBody['ecoMode'] = this.ecoMode ? 1 : 0;
    msgBody['uzemmod'] = this.ecoSetting;

    this._websocket.sendMessage(msgBody);
  }

  stopSending() {
    console.log('%c<<STOP SENDING>>', 'color: blue; font-weight: bold');
    clearInterval(this.sendInterval);
    clearTimeout(this.sendTimeout);
    this.sendInterval = null;
    this.sendTimeout = null;
  }

  checkStates(msg: any) {
    let valid = true;
    if (msg['onoff'] != undefined) {
      valid = msg['onoff'] == this.localOnOff;
    }
    if (valid && msg['mode'] != undefined) {
      valid = msg['mode'] == this.localMode;
    }
    if (valid && msg['setTemperature'] != undefined) {
      valid = msg['setTemperature'] == this.localTemp;
    }
    if (valid && msg['fanSpeed'] != undefined) {
      valid = msg['fanSpeed'] == this.localFan;
    }

    return valid;
  }

  /*Kulcs-érték pár alapján MAIN és DETAIL info küldés
    BUFFER nélkül*/
  public sendMainInfo(key: MAIN_INFO_NAMES, value: any) {
    if (this.acId != null && this._websocket.connected) {
      this._websocket.set(key, value);
    }
  }

  public sendAcTimerState(message: any) {
    this._websocket.sendAcTimerState(message);
  }
  /*------------------------------------------------------------------------------------------------*/

  /*MAIN képernyő működése*/
  /*------------------------------------------------------------------------------------------------*/
  /*Csatlakozás a websockethez*/
  public initRefresh(
    acId: number = this.acId,
    acMac: string = this.acMac,
    customerId: any = null
  ) {
    if (acId != null) {
      this.ac = acId;
      this.acMac = acMac;
      this.connectToWs(acMac, customerId);
    }
  }

  /*Hőmérséklet beállítása - megfelelő koverziókkal*/
  public setTemperature(up: boolean) {
    if (this.acId != null && !this.lostConnection && this.setTemp != 'N/A') {
      let prevTemp = this.localTemp;
      let temp = this.getTemp(this.localTemp);
      if (typeof temp == 'number') {
        let max =
          this.cF.toUpperCase() == 'C'
            ? parseInt(json_configs.maxTemp)
            : this.C2F(parseInt(json_configs.maxTemp));
        let min =
          this.cF.toUpperCase() == 'C'
            ? parseInt(json_configs.minTemp)
            : this.C2F(parseInt(json_configs.minTemp));
        if (up && temp + 1 <= max) {
          temp++;
        } else if (!up && temp - 1 >= min) {
          temp--;
        }
        this.localTemp =
          this.cF.toUpperCase() == 'C'
            ? Math.round(this.encodeTemp(temp))
            : Math.round(this.encodeTemp(this.F2C(temp)));
        if (prevTemp != this.localTemp) {
          this.beginSending(MAIN_INFO.SET_TEMP);
        }
      }
    }
  }

  /*Ventillátor beállítása*/
  public setFan() {
    if (this.acId != null && !this.lostConnection) {
      let fan = this.fan;
      let max = this.info.fanSteps >= FAN_STEPS.TYPE2 ? 6 : 4;
      if (fan < max) {
        fan++;
      } else if (fan == max) {
        fan = 1;
      }
      this.localFan = fan;
      this.beginSending(MAIN_INFO.FAN);
    }
  }

  /*Mode beállítása*/
  public setMode() {
    if (this.acId != null && !this.lostConnection) {
      let mode = this.mode;
      if (mode == 1) {
        mode = 3;
      } else if (mode == 3) {
        mode = 4;
      } else if (mode == 4) {
        mode = 5;
      } else if (mode == 4) {
        mode = 1;
      } else {
        mode = 1;
      }
      this.localMode = mode;
      this.beginSending(MAIN_INFO.MODE);
    }
  }

  public setEcoSetting() {
    if (this.acId != null && !this.lostConnection) {
      let ecoSetting = this.ecoSetting;
      if (ecoSetting == 0) {
        ecoSetting = 1;
      } else if (ecoSetting == 1) {
        ecoSetting = 2;
      } else if (ecoSetting == 2) {
        ecoSetting = 0;
      }
      this.localEcoSetting = ecoSetting;
      this.beginSending(MAIN_INFO.ECO_SETTING);
    }
  }

  /*AC ki/be kapcsolása*/
  public setOnOff() {
    if (this.acId != null && !this.lostConnection) {
      let onoff =
        this.localOnOff == null ? this.info['onoff'] : this.localOnOff;
      onoff = onoff == '1' ? '0' : '1';
      this.localOnOff = onoff;
      this.beginSending(MAIN_INFO.ONOFF);
    }
  }

  /*------------------------------------------------------------------------------------------------*/

  /*DETAILS képernyő működése*/
  /*------------------------------------------------------------------------------------------------*/
  /*AC nyelvének átállítása - Nincs hatása a felület nyelvére!*/
  public toggleLangs(langList: any[]) {
    if (this.acId != null) {
      let ind = langList.indexOf(this.lang);
      if (ind + 1 > langList.length - 1 || langList[ind] == undefined) {
        ind = 0;
      } else {
        ind++;
      }
      this.localAcLang = langList[ind];
      this.sendMainInfo(MAIN_INFO_NAMES.LANG, this.localAcLang);
      this.chs.setLang(this.localAcLang);
    }
  }

  /*Hőmérséklet mértékegység átváltás*/
  public changeCF() {
    if (this.acId != null) {
      let cF =
        this.localCF == null ? this.info['temperatureScale'] : this.localCF;
      if (cF.toUpperCase() == 'C') {
        cF = 'F';
      } else {
        cF = 'C';
      }
      this.localTemp = this.localTemp;
      this.localCF = cF;
      this.sendMainInfo(MAIN_INFO_NAMES.SCALE, cF.toLowerCase());
    }
  }

  /*------------------------------------------------------------------------------------------------*/

  /*Hőmérséklet konverzió - segédfüggvények*/
  getTemp(temp: any) {
    if (temp == 0) {
      return '--';
    } else if (temp == '--') {
      return '--';
    } else if (this.cF.toUpperCase() == 'C') {
      let innerTemp = Math.round(this.decodeTemp(temp));
      return innerTemp == 0 ? '--' : innerTemp;
    } else {
      let celsius = this.decodeTemp(temp);
      let innerTemp = Math.round(this.C2F(celsius));
      return innerTemp == 0 ? '--' : innerTemp;
    }
  }

  C2F(temp: number) {
    return temp * 1.8 + 32;
  }

  F2C(temp: number) {
    return (temp - 32) / 1.8;
  }

  /*A normalizáltan érkező temp-et tényleges értékké kell konvertálni*/
  decodeTemp(temp: number) {
    return (temp - 80) / 2;
  }

  encodeTemp(temp: number) {
    return temp * 2 + 80;
  }
}
