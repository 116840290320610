import { getLocaleNumberFormat } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { FAN_STEPS, MAIN_INFO } from 'src/app/core/models/configs';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';
import {
  SPINNER_PLACEMENT,
  SPINNER_ANIMATIONS,
  ISpinnerConfig,
} from '@hardpool/ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  boats: any[] = [];
  boatList: Observable<any[]>;
  acs: any[] = [];
  acsToSelect = [];
  acList: Observable<any[]>;

  tempBuffer = null;
  tempTimer = null;

  mainInfo = MAIN_INFO;
  fanSteps = FAN_STEPS;

  selectedAcId: number;
  selectedAc: any;
  selectedBoatId: number;

  spinnerConfig: ISpinnerConfig;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private authService: AuthService,
    private apiCallService: ApiCallService,
    public chs: CommonHandlerService,
    public infoService: InfoService
  ) {}

  ngOnInit(): void {
    this.spinnerConfig = {
      placement: SPINNER_PLACEMENT.inplace,
      animation: SPINNER_ANIMATIONS.spin_3,
      size: '64px',
      color: '#ffffff',
    };

    if (environment.production) {
      window.addEventListener('focus', () => {
        if (this.isIOS()) {
          this.infoService.spinnerVisible = true;
          setTimeout(
            function () {
              this.infoService.reconnectToWs(this.selectedAc['mac']);
            }.bind(this),
            1000
          );
        }
      });
    }

    //Inicializáljuk az AC listát
    this.initAcs(true);
    this.getBoatList();

    this.infoService.nameChange.subscribe((event) => {
      for (let i in this.acs) {
        if (this.acs[i].id == this.selectedAcId) {
          this.acs[i].label = event == null ? this.acs[i].mac : event;
        }
      }
      this.infoService.info.name = event;
      this.infoService.localAcChanged = true;
      this.acs = [...this.acs];
      this.acList = of(this.acs);
    });
  }

  logout() {
    return this.authService.logout().subscribe();
  }

  isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  /*LEGACY*/
  initAcs(hasInit: boolean = false) {
    this.acs = this.route.snapshot.data.pageData;
    for (let i in this.acs) {
      this.acs[i]['label'] =
        this.acs[i]['name'] != null && this.acs[i]['name'].length > 0
          ? this.acs[i]['name']
          : this.acs[i]['mac'].match(/.{2}/g).join(':');
    }
    this.acList = of(this.acs);

    this.selectedAcId =
      this.infoService.ac == null ? this.acs[0].id : this.infoService.ac;
    this.infoService.ac = this.selectedAcId;
    this.selectedAc =
      this.acs.find((ac) => ac.id == this.infoService.ac) || this.acs[0];

    if (hasInit) {
      this.infoService.initRefresh(
        this.selectedAcId,
        this.selectedAc['mac'],
        this.selectedAc['customer_id']
      );
    }
  }

  onAcChange() {
    let ac = this.acs.find((ac) => ac.id == this.selectedAcId);
    this.selectedAc = ac;
    this.infoService.ac = this.selectedAcId;
    this.getAcBoat();
    this.infoService.initRefresh(
      this.selectedAcId,
      ac['mac'],
      ac['customer_id']
    );
  }

  onBoatChange() {
    this.filterAcs(this.selectedBoatId);
  }

  getBoatList() {
    this.boatList = this.apiCallService.apiGet<ApiResponse>('/api/boats').pipe(
      mergeMap((response) => {
        if (response && response.isSuccess()) {
          this.boats = response.data;
          this.boats.unshift({
            name: this.chs.getText('mindegyik'),
            boat_id: -1,
          });
          //Kell lennie már ekkor kiválasztott default AC-nak
          let boatId = this.selectedAc['boatId'];
          //Ha van hajó az AC-hoz
          if (boatId != undefined && boatId != null) {
            this.selectedBoatId = boatId;
          } else {
            this.selectedBoatId = -1;
          }

          this.filterAcs(boatId);

          return of(this.boats);
        } else {
          this.boats = [];
          return EMPTY;
        }
      })
    );
  }

  filterAcs(boatId: number = null) {
    this.initAcs();
    if (boatId != null && boatId > 0) {
      this.acs = this.acs.filter((ac) => ac.boatId == boatId);
      this.acList = of(this.acs);
    }

    let ac = this.acs.find((ac) => ac.id == this.infoService.ac);
    if (this.infoService.ac != null && ac != undefined) {
      this.selectedAcId = ac.id;
      this.selectedAc = ac;
      this.infoService.initRefresh(
        this.selectedAcId,
        this.selectedAc['mac'],
        this.selectedAc['customer_id']
      );
    } else {
      this.selectedAcId = this.acs.length > 0 ? this.acs[0].id : null;
      this.infoService.ac = this.selectedAcId;
      this.selectedAc = this.acs.length > 0 ? this.acs[0] : null;
      if (this.selectedAc != null) {
        this.infoService.initRefresh(
          this.selectedAcId,
          this.selectedAc['mac'],
          this.selectedAc['customer_id']
        );
      }
    }
  }

  getAcBoat() {
    if (this.selectedAc != null && this.selectedAc['boatId'] != null) {
      this.selectedBoatId = this.selectedAc['boatId'];
    } else {
      this.selectedBoatId = -1;
    }

    if (this.selectedBoatId != -1) {
      this.filterAcs(this.selectedBoatId);
    }
  }

  getFanSpeedRotate() {
    //Lehet, hogy nincs bekapcsolva a ventillátor
    /*
      Fan Auto Off: ha be van kapcsolva a klíma akkor megy
      Fan Auto On: ha ventilátor módban van és be van kapcsolva a klíma akkor megy, minden más módban ha megy a kompresszor akkor megy.
    */
    let fanOn = false;
    let animClass = 'rotate-';

    if (this.infoService.fanAutoOn) {
      if (this.infoService.mode == 3 && this.infoService.onoff == '1') {
        fanOn = true;
      } else if (
        this.infoService.mode != 3 &&
        this.infoService.info['comp'] == '1'
      ) {
        fanOn = true;
      }
    } else {
      if (this.infoService.onoff == '1') {
        fanOn = true;
      }
    }

    if (!fanOn) {
      return '';
    }

    if (this.infoService.info.fanSteps <= FAN_STEPS.TYPE1 + 1) {
      if (this.infoService.fan == 3) {
        return animClass + '5';
      } else if (this.infoService.fan == 2) {
        return animClass + '3';
      } else if (this.infoService.fan == 1) {
        return animClass + '1';
      } else if (this.infoService.fan == 4) {
        return animClass + '3';
      }
    } else {
      if (this.infoService.fan == 5) {
        return animClass + '5';
      } else if (this.infoService.fan == 4) {
        return animClass + '4';
      } else if (this.infoService.fan == 3) {
        return animClass + '3';
      } else if (this.infoService.fan == 2) {
        return animClass + '2';
      } else if (this.infoService.fan == 1) {
        return animClass + '1';
      } else if (this.infoService.fan == 6) {
        return animClass + '3';
      }
    }
  }
}
