<div class="home-row" [ngClass]="{ error: errorMode }">
  <a class="home-block" routerLink="/home">
    <img *ngIf="!errorMode" src="assets/icons/house.svg" />
    <img *ngIf="errorMode" src="assets/icons/house_red.svg" />
    <span class="home-link upper">{{ chs.getText("fooldal") }}</span>
  </a>
  <a
    *ngIf="altMode"
    class="home-block bordered padd"
    [routerLink]="'/ac-units'"
  >
    <img *ngIf="!errorMode" src="assets/icons/bars.svg" />
    <img *ngIf="errorMode" src="assets/icons/bars_red.svg" />
    <span class="home-link upper">{{ chs.getText("beallitasok") }}</span>
  </a>
  <a
    *ngIf="!altMode"
    class="home-block bordered padd"
    [routerLink]="'/details'"
  >
    <img *ngIf="!errorMode" src="assets/icons/bars.svg" />
    <img *ngIf="errorMode" src="assets/icons/bars_red.svg" />
    <span class="home-link upper">{{ chs.getText("reszletek") }}</span>
  </a>
  <a
    class="home-block"
    [ngClass]="{ 'alert-error': infoService.hasError }"
    [routerLink]="'/alert'"
  >
    <img
      *ngIf="!errorMode && !infoService.hasError"
      src="assets/icons/alarm.svg"
    />
    <img
      *ngIf="errorMode || infoService.hasError"
      src="assets/icons/alarm_red.svg"
    />
    <span class="home-link upper">{{ chs.getText("uzenetek") }}</span>
  </a>
</div>
