import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { Customer } from 'src/app/core/models/customer';
import { Microcontroller } from 'src/app/core/models/microcontroller';
import { ROLE } from 'src/app/core/models/users/roles';
import { WSClient } from 'src/app/core/models/websocket';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  controllers: Microcontroller[];
  customers: Customer[] = [];
  filter: string = '';

  wsClient: WSClient;

  ColumnMode = ColumnMode;
  ROLE = ROLE;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    public authService: AuthService,
    private adminService: AdminService,
    private cutromerService: CustomerService,
    private infoService: InfoService,
    public chs: CommonHandlerService
  ) {}

  ngOnInit() {
    this.adminService
      .getAdminControllers()
      .pipe(
        tap((controllers) => {
          this.controllers = controllers;
        }),
        switchMap(() => {
          return this.cutromerService.getCustomers();
        }),
        tap((customers) => (this.customers = customers))
      )
      .subscribe();

    this.wsClient = new WSClient();
    this.wsClient.adminConnect();
    this.wsClient.socket.subscribe((message) => this.handleWSMessage(message));
  }

  get filteredControllers() {
    const adjustedFilter = this.filter.toLowerCase().replace(/-/g, '');
    return this.controllers?.filter((c) => {
      return (
        c.mac.toLowerCase().includes(adjustedFilter) ||
        c.name.toLowerCase().includes(adjustedFilter) ||
        this.filterCustomer(this.filter, c.customer_id) ||
        !this.filter
      );
    });
  }

  getMac(mac: string) {
    return mac?.match(/.{2}/g)?.join('-');
  }

  getCustomer(id: number) {
    return this.customers?.find((customer) => customer.id === id)?.name;
  }

  getTemp(controller: Microcontroller, tempInd: string) {
    if (!!controller) {
      let celsius = this.infoService.decodeTemp(controller[tempInd]);
      if (controller.temperatureScale.toLowerCase() === 'c') {
        return (
          Math.round(celsius) + ' ' + controller.temperatureScale.toUpperCase()
        );
      } else {
        return (
          Math.round(this.infoService.C2F(celsius)) +
          ' ' +
          controller.temperatureScale.toUpperCase()
        );
      }
    }
  }

  getErrors(controller: Microcontroller) {
    var list = [];
    for (let i = 0; i < 14; i++) {
      if (controller['error_' + i] == 1) {
        list.push(i + ': ' + this.chs.getText('error_' + i, true));
      }
    }
    return list;
  }

  checkStatus(row: any) {
    const now = new Date();

    const activity = new Date(row.lastActivity);
    return Math.abs(now.getTime() - activity.getTime()) / 1000 < 60;
  }

  private filterCustomer(value: string, customerId: number) {
    if (!this.authService.canAccess(ROLE.SUPERUSER)) {
      return false;
    }
    return this.customers
      .filter((customer) => customer.name.includes(value))
      .some((customer) => customer.id === customerId);
  }

  private handleWSMessage(message: ApiResponse) {
    let acId = message.data['ac']['id'];
    let ind = this.controllers.findIndex((controller) => controller.id == acId);
    this.controllers[ind] = message.data['ac'];
    this.controllers = [...this.controllers];
    console.log(message);
  }
}
