<div class="page-wrapper">
  <div class="container margin-top-20">
    <div class="row justify-content-center">
      <div class="col-8">
        <img
          class="qr"
          src="../../../assets/images/website_qr.png"
          alt="https://mbc-marine.com/downloads/"
        />
      </div>
    </div>

    <div class="row justify-content-center margin-top-20">
      <div class="col-12 text-center">
        <a
          class="underline"
          href="{{ chs.getText('mbc_website_link') }}"
          target="_blank"
          >{{ chs.getText("mbc_website_link_szoveg") }}</a
        >
      </div>
    </div>
  </div>
</div>

<div class="flex center-content bottom-holder">
  <a routerLink="/home" class="btn thin w-fit mt-3">{{
    chs.getText("fooldal")
  }}</a>
</div>

<img class="bg-img" src="assets/background/details_bg.svg" />
