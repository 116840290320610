<a class="admin-home-link" routerLink="/admin/home">HOME</a>
<h1>Szériák karbantartása</h1>

<div *ngIf="mode == 'L'">
  <div>
    <label for="mac">AC ID szűrő</label>
    <input id="mac" type="text" [(ngModel)]="macFilter" />
  </div>

  <div class="margin-top-20">
    <label for="serial">Szériaszám szűrő</label>
    <input id="serial" type="text" [(ngModel)]="serialFilter" />
  </div>

  <button class="margin-top-20 thin" (click)="getSerials()">Szűrés</button>

  <h2>Találatok</h2>
  <table class="margin-top-20">
    <tr>
      <th>AC ID</th>
      <th>Szériaszám</th>
    </tr>
    <tr *ngFor="let serial of serials">
      <td>{{ formatMAC(serial.mac) }}</td>
      <td>
        <input [value]="serial.serial" (blur)="set($event, serial.mac)" />
      </td>
    </tr>
  </table>

  <button class="margin-top-20 thin" (click)="mode = 'N'">Új rögzítése</button>
</div>

<div *ngIf="mode == 'N'">
  <div>
    <label for="nmac">AC ID</label>
    <input
      id="nmac"
      [mask]="'AA-AA-AA-AA-AA-AA'"
      type="text"
      [(ngModel)]="newAcId"
    />
  </div>

  <div class="margin-top-20">
    <label for="nserial">Szériaszám</label>
    <input id="nserial" type="text" [(ngModel)]="newSerial" />
  </div>

  <button class="margin-top-20 thin" (click)="setNew()">Rögzítés</button>
</div>
