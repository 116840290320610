<div class="page-wrapper">
  <div class="center-content">
    <h2 class="margin-top-30">{{ chs.getText("uj_hajo_felvetele") }}</h2>
  </div>

  <form [formGroup]="newForm" (ngSubmit)="addBoat()">
    <div class="form-row margin-top-30">
      <label>{{ chs.getText("nev") }}:</label>
      <input
        type="text"
        formControlName="name"
        maxlength="255"
        [ngClass]="{ 'is-invalid': submitted && newForm.controls.name.errors }"
      />
    </div>

    <div class="form-row">
      <label>{{ chs.getText("kikoto") }}:</label>
      <input
        type="text"
        formControlName="port"
        maxlength="255"
        [ngClass]="{ 'is-invalid': submitted && newForm.controls.port.errors }"
      />
    </div>
    <div class="form-row">
      <label>{{ chs.getText("dokk") }}:</label>
      <input
        type="text"
        formControlName="dock"
        maxlength="255"
        [ngClass]="{ 'is-invalid': submitted && newForm.controls.dock.errors }"
      />
    </div>
    <div *ngIf="hasError('dock')">
      <div class="input-error" *ngIf="newForm.controls['dock'].errors?.invalid">
        {{ newForm.controls["dock"].errors?.message }}
      </div>
    </div>
    <div class="form-row">
      <label>{{ chs.getText("lajstromszam") }}:</label>
      <input
        type="text"
        formControlName="registration_number"
        maxlength="255"
        [ngClass]="{
          'is-invalid': submitted && newForm.controls.registration_number.errors
        }"
      />
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>
    <div *ngIf="message != null" class="message-holder">
      {{ message }}
    </div>

    <div *ngIf="!confirm" class="btn-holder center-content margin-top-50">
      <button class="btn-100 btn-center" type="submit">
        {{ chs.getText("hozzaadas") }}
      </button>
      <a class="btn thin btn-100 btn-center" routerLink="/boats">{{
        chs.getText("vissza")
      }}</a>
    </div>

    <!--<div *ngIf="confirm" class="false-popup">
        <p  class="center-content margin-top-30" >{{ chs.getText('hajo_letrehozas_megerosites') }}</p>

        <div class="space-content margin-top-50">
            <button type="submit" class="thin" >{{ chs.getText('hozzaadas') }}</button>
            <button class="thin" (click)="initAdd(false)">{{ chs.getText('megsem') }}</button>
        </div>
    </div>-->
  </form>

  <img class="bg-img" src="assets/background/side_bg.svg" />
</div>
