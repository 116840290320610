import { Component, OnInit } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Customer } from 'src/app/core/models/customer';
import { Microcontroller } from 'src/app/core/models/microcontroller';
import { ROLE } from 'src/app/core/models/users/roles';
import { User } from 'src/app/core/models/users/user';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { CustomerService } from 'src/app/core/services/customer.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsers implements OnInit {
  users: User[];
  selectedUser: User = null;
  filter: string = '';
  ownedControllers: Microcontroller[] = [];
  managedControllers: any[] = [];
  selectedController: Microcontroller = null;
  customers: Customer[] = [];

  roles = Object.keys(ROLE)
    .map((role) => role.toLowerCase())
    .filter((role) => role !== ROLE.OWNER);

  constructor(
    private adminService: AdminService,
    public authService: AuthService,
    private customerService: CustomerService,
    public chs: CommonHandlerService
  ) {}

  getCustomerName(customerId: number) {
    return this.customers.find((c) => c.id === customerId)?.name;
  }

  getOwnerName(userId: number) {
    const user = this.users.find((u) => u.id === userId);
    return `${user?.first_name} ${user?.last_name}`;
  }

  ngOnInit() {
    this.getUsers();
    this.getOwnedControllers();
    this.getManagedControllers();
    this.getCustomers();
  }

  getUsers() {
    this.adminService.getUsers().subscribe((u) => (this.users = u));
  }

  getOwnedControllers() {
    this.adminService
      .getAdminControllers()
      .subscribe((controllers) => (this.ownedControllers = controllers));
  }

  getManagedControllers() {
    this.adminService
      .getManagedControllers()
      .subscribe((controllers) => (this.managedControllers = controllers));
  }

  getCustomers() {
    this.customerService
      .getCustomers()
      .subscribe((customers) => (this.customers = customers));
  }

  selectUser(user: User) {
    this.selectedUser = user;
  }

  changeRole() {
    this.adminService
      .changeUserRole(this.selectedUser)
      .subscribe((response) => {
        if (!response.isSuccess()) {
          alert('Error updating user!');
        }
      });
  }

  changeCustomerId() {
    this.adminService
      .adminModControllerCustomer(
        this.selectedController.id,
        this.customers.find(
          (customer) =>
            customer.customer_id === this.selectedController.customer_id
        )?.id
      )
      .subscribe((response) => {
        if (!response.isSuccess()) {
          alert('Unable to update customer ID!');
        } else {
          this.getOwnedControllers();
        }
        this.selectedController = null;
      });
  }

  disconnectControllerFormUser(controllerId: number) {
    if (confirm('Are you sure to disconnect the controller from the user?')) {
      this.adminService
        .adminDisconnectControllerFromUser(controllerId)
        .subscribe((response) => {
          if (!response.isSuccess()) {
            alert('Unable to disconnect controller');
          } else {
            this.getOwnedControllers();
          }
        });
    }
  }

  checkFilter(user: User) {
    return (
      user.last_name.toLowerCase().includes(this.filter.toLowerCase()) ||
      user.first_name.toLowerCase().includes(this.filter.toLowerCase()) ||
      user.email.toLowerCase().includes(this.filter.toLowerCase())
    );
  }

  filterUserControllers() {
    return this.ownedControllers.filter(
      (controller) => controller.userId === this.selectedUser.id
    );
  }

  filterUserManagedControllers() {
    return this.managedControllers.find(
      (data) => data.id === this.selectedUser.id
    )?.microcontrollers;
  }

  exportUsers() {
    const exp: any = [];
    this.users.forEach((user: User) => {
      exp.push({
        Id: user.id,
        'First Name': user.first_name,
        'Last Name': user.last_name,
        Role: user.role,
        Mobile: user.mobile,
        Email: user.email,
      });
    });
    new ngxCsv(exp, 'MBC user list', {
      headers: ['Id', 'First Name', 'Last Name', 'Role', 'Mobile', 'Email'],
    });
  }
}
