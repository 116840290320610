<div class="page-wrapper">
  <h1 class="mb-3 upper text-center">
    {{ chs.getText("ventillator_beallitasok") }}
  </h1>
  <h2>{{ acMac | mac }}</h2>

  <form
    [formGroup]="fanConfigForm"
    (ngSubmit)="onSaveSettings()"
    class="align-items-sm-end justify-content-sm-center margin-top-30"
  >
    <div class="d-flex flex-column mw-200 mr-sm-3">
      <ng-select
        appearance="outline"
        [searchable]="false"
        [items]="fanSpeedList"
        bindLabel="label"
        bindValue="value"
        formControlName="fanSpeed"
        class="mb-3"
      >
      </ng-select>

      <ng-select
        appearance="outline"
        [searchable]="false"
        [items]="fanTypeList"
        bindLabel="label"
        bindValue="value"
        formControlName="fanType"
      >
      </ng-select>
    </div>

    <div class="d-flex mt-4 justify-content-between justify-content-sm-start">
      <div class="d-flex flex-column align-items-center mr-sm-4">
        <div class="mb-1"><strong>1</strong></div>
        <app-num-selector
          orientation="vertical"
          [value]="getFanStep(0) | async"
          [minVal]="1"
          [maxVal]="getMaxFanStep(0) | async"
          (onChange)="onStepChange($event, 0)"
        ></app-num-selector>
      </div>

      <div
        class="d-flex flex-column align-items-center mr-sm-4 justify-content-center relation"
      >
        <div>&lt;</div>
        <div>=</div>
      </div>

      <div class="d-flex flex-column align-items-center mr-sm-4">
        <div class="mb-1"><strong>2</strong></div>
        <app-num-selector
          orientation="vertical"
          [value]="getFanStep(1) | async"
          [minVal]="getMinFanStep(1) | async"
          [maxVal]="getMaxFanStep(1) | async"
          (onChange)="onStepChange($event, 1)"
          [inactive]="!checkFanStep(5)"
        ></app-num-selector>
      </div>

      <div
        class="d-flex flex-column align-items-center mr-sm-4 justify-content-center relation"
      >
        <div>&lt;</div>
        <div>=</div>
      </div>

      <div class="d-flex flex-column align-items-center mr-sm-4">
        <div class="mb-1"><strong>3</strong></div>
        <app-num-selector
          orientation="vertical"
          [value]="getFanStep(2) | async"
          [minVal]="getMinFanStep(2) | async"
          [maxVal]="getMaxFanStep(2) | async"
          (onChange)="onStepChange($event, 2)"
          [inactive]="!checkFanStep(3)"
        ></app-num-selector>
      </div>

      <div
        class="d-flex flex-column align-items-center mr-sm-4 justify-content-center relation"
      >
        <div>&lt;</div>
        <div>=</div>
      </div>

      <div class="d-flex flex-column align-items-center mr-sm-4">
        <div class="mb-1"><strong>4</strong></div>
        <app-num-selector
          orientation="vertical"
          [value]="getFanStep(3) | async"
          [minVal]="getMinFanStep(3) | async"
          [maxVal]="getMaxFanStep(3) | async"
          (onChange)="onStepChange($event, 3)"
          [inactive]="!checkFanStep(5)"
        ></app-num-selector>
      </div>

      <div
        class="d-flex flex-column align-items-center mr-sm-4 justify-content-center relation"
      >
        <div>&lt;</div>
        <div>=</div>
      </div>

      <div class="d-flex flex-column align-items-center mr-sm-4">
        <div class="mb-1"><strong>5</strong></div>
        <app-num-selector
          orientation="vertical"
          [value]="getFanStep(4) | async"
          [minVal]="getMinFanStep(4) | async"
          [maxVal]="99"
          (onChange)="onStepChange($event, 4)"
          [inactive]="!checkFanStep(3)"
        ></app-num-selector>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button class="mt-5" type="submit">Save</button>
    </div>
  </form>
</div>
