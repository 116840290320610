import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mac',
})
export class MacPipe {
  transform(mac: string) {
    return mac?.match(/.{2}/g).join('-');
  }
}
