import { Component, OnInit } from '@angular/core';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
  constructor(public chs: CommonHandlerService) {}

  ngOnInit(): void {}
}
