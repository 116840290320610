<div class="page-wrapper">
  <div class="upper-row">
    <img
      class="btn-like"
      (click)="infoService.setOnOff()"
      *ngIf="infoService.onoff == 1"
      src="assets/images/on.svg"
    />
    <img
      class="btn-like"
      (click)="infoService.setOnOff()"
      *ngIf="infoService.onoff == 0"
      src="assets/images/off.svg"
    />
    <div>
      <ng-select
        appearance="outline"
        [searchable]="false"
        [items]="boatList | async"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ chs.getText('hajo') }} "
        [(ngModel)]="selectedBoatId"
        (change)="onBoatChange()"
      >
      </ng-select>
      <ng-select
        appearance="outline"
        [searchable]="false"
        [items]="acList | async"
        bindLabel="label"
        bindValue="id"
        placeholder="{{ chs.getText('ac_egyseg') }} "
        [(ngModel)]="selectedAcId"
        (change)="onAcChange()"
      >
      </ng-select>
    </div>
  </div>

  <hr />

  <div
    class="prominent"
    [ngClass]="{ fade: !infoService.connected || infoService.spinnerVisible }"
  >
    <div class="temp-row">
      <div class="temp-holder">
        <div>
          <span class="temp"
            >{{ infoService.realTemp
            }}<sup>{{ infoService.cF.toUpperCase() }}</sup></span
          >
        </div>
        <div>
          <span
            class="set-temp"
            [ngClass]="{
              max: infoService.isTempMax,
              min: infoService.isTempMin
            }"
            >{{ infoService.setTemp }}<sup>SET</sup></span
          >
        </div>
      </div>
      <div class="temp-holder">
        <div
          class="indicator-holder"
          *ngIf="infoService.info.fanSteps >= fanSteps.TYPE2"
        >
          <div class="indicator">
            <img
              [ngClass]="{ none: infoService.fan < 5 }"
              src="assets/indicators/indicator5.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan > 4 }"
              src="assets/indicators/indicator_blank5.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan < 4 }"
              src="assets/indicators/indicator4.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan > 3 }"
              src="assets/indicators/indicator_blank4.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan < 3 }"
              src="assets/indicators/indicator3.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan > 2 }"
              src="assets/indicators/indicator_blank3.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan < 2 }"
              src="assets/indicators/indicator2.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan > 1 }"
              src="assets/indicators/indicator_blank2.svg"
            />
            <img src="assets/indicators/indicator1.svg" />

            <div class="abs"><img src="assets/icons/fan.svg" /></div>
          </div>
          <div
            class="rotate"
            [ngClass]="{ invisible: infoService.fan < 6 }"
            src="assets/indicators/indicator1.svg"
          >
            AUTO
          </div>
        </div>

        <div
          class="indicator-holder"
          *ngIf="infoService.info.fanSteps < fanSteps.TYPE2"
        >
          <div class="indicator spaced">
            <img
              [ngClass]="{ none: infoService.fan < 3 }"
              src="assets/indicators/indicator5.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan > 2 }"
              src="assets/indicators/indicator_blank5.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan < 2 }"
              src="assets/indicators/indicator3.svg"
            />
            <img
              [ngClass]="{ none: infoService.fan > 1 }"
              src="assets/indicators/indicator_blank3.svg"
            />
            <img src="assets/indicators/indicator1.svg" />

            <div class="abs"><img src="assets/icons/fan.svg" /></div>
          </div>
          <div
            class="rotate adjust"
            [ngClass]="{ invisible: infoService.fan < 4 }"
            src="assets/indicators/indicator1.svg"
          >
            AUTO
          </div>
        </div>
      </div>
      <div class="icon-holder">
        <img
          [ngClass]="{ none: !infoService.info.comp }"
          src="assets/icons/compressor_on.svg"
        />
        <img
          [ngClass]="{ none: infoService.info.comp }"
          src="assets/icons/compressor_off.svg"
        />

        <img
          [ngClass]="{ none: !infoService.info.pump }"
          src="assets/icons/pump_on.svg"
        />
        <img
          [ngClass]="{ none: infoService.info.pump }"
          src="assets/icons/pump_off.svg"
        />

        <img
          [ngClass]="{ none: !infoService.info.valve }"
          src="assets/icons/valve_on.svg"
        />
        <img
          [ngClass]="{ none: infoService.info.valve }"
          src="assets/icons/valve_off.svg"
        />
      </div>
    </div>

    <hr />

    <div class="space-content">
      <img
        class="btn-like non-selectable"
        [ngClass]="{ disabled: !infoService.connected }"
        (click)="infoService.setTemperature(true)"
        src="assets/images/up_btn.svg"
      />
      <img
        class="btn-like non-selectable"
        [ngClass]="{ disabled: !infoService.connected }"
        (click)="infoService.setTemperature(false)"
        src="assets/images/down_btn.svg"
      />
    </div>
    <div class="space-content margin-top-30">
      <button
        (click)="infoService.setMode()"
        [disabled]="!infoService.connected"
        type="button"
        class="btn-like non-selectable mode"
      >
        <img
          class="abs"
          src="assets/icons/fan.svg"
          [ngClass]="{
            none: infoService.mode != undefined && infoService.mode != null
          }"
        />
        <img
          class="abs"
          src="assets/icons/snowflake_mode_new.svg"
          [ngClass]="{ none: infoService.mode != 1 }"
        />
        <img
          class="abs"
          src="assets/icons/fan.svg"
          [ngClass]="{ none: infoService.mode != 3 }"
        />
        <img
          class="abs"
          src="assets/icons/sun_mode_new.svg"
          [ngClass]="{ none: infoService.mode != 4 }"
        />
        <img
          class="abs"
          src="assets/icons/auto_mode.svg"
          [ngClass]="{ none: infoService.mode != 5 }"
        />
        <!--<div class="abs auto-mode" [ngClass]="{'none': infoService.mode != 5}">A</div>-->
        {{ chs.getText("mod") }}
      </button>
      <button
        (click)="infoService.setFan()"
        [disabled]="!infoService.connected"
        type="button"
        class="btn-like non-selectable"
      >
        <div
          class="margin-right-8 margin-bottom-8"
          [ngClass]="{ pulse: infoService.fanAutoOn }"
        >
          <img
            class="btn-like"
            [ngClass]="getFanSpeedRotate()"
            src="assets/icons/fan.svg"
          />
        </div>
        {{ chs.getText("propeller") }}
      </button>
    </div>

    <div class="holder">
      <button
        *ngIf="infoService.ecoMode"
        (click)="infoService.setEcoSetting()"
        [disabled]="!infoService.connected"
        type="button"
        class="btn-like non-selectable eco"
      >
        <img
          class="abs"
          src="assets/icons/leaf_blue.png"
          *ngIf="infoService.ecoSetting == 0"
        />
        <span *ngIf="infoService.ecoSetting == 0">NORMAL</span>
        <img
          class="abs"
          src="assets/icons/leaf_grn.png"
          *ngIf="infoService.ecoSetting == 1"
        />
        <span *ngIf="infoService.ecoSetting == 1">ECO</span>
        <img
          class="abs"
          src="assets/icons/rocket.png"
          *ngIf="infoService.ecoSetting == 2"
        />
        <span *ngIf="infoService.ecoSetting == 2">TURBO</span>
      </button>
    </div>
  </div>

  <div class="no-connection" *ngIf="!infoService.connected">
    {{ chs.getText("nincs_kapcsolat", true) }}
  </div>

  <div class="bottom-holder">
    <app-home-menu [errorMode]="!infoService.connected"></app-home-menu>
  </div>

  <img class="bg-img" src="assets/background/home_bg.svg" />
</div>

<ngx-spinner
  id="spinner"
  [visible]="infoService.spinnerVisible"
  [config]="spinnerConfig"
></ngx-spinner>
