import { Component, Input, OnInit } from '@angular/core';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
})
export class HomeMenuComponent implements OnInit {
  @Input() altMode = false;
  @Input() errorMode = false;

  constructor(
    public chs: CommonHandlerService,
    public infoService: InfoService,
  ) {}

  ngOnInit(): void {}
}
