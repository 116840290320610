<div class="page-wrapper">
  <img src="assets/background/email_confirm_bg.svg" />

  <ng-container>
    <h1>
      Welcome <br />
      to MBC Marine
    </h1>
    <p *ngIf="confirmed == '1'">
      {{ chs.getText("regisztracio_aktivalas_sikeres") }}
    </p>

    <p *ngIf="confirmed == '0'">
      {{ chs.getText("regisztracio_aktivalas_sikertelen") }}
    </p>

    <div class="center-content">
      <a href="/login" class="btn" *ngIf="confirmed == '1'">{{
        chs.getText("belepes")
      }}</a>
      <a href="/resend-activation" class="btn" *ngIf="confirmed == '0'">{{
        chs.getText("ujrakuld")
      }}</a>
    </div>
  </ng-container>
</div>
