<div class="ac-row">
  <a class="ac-block" routerLink="/ac-units">
    <img src="assets/icons/ac.svg" />
    <span class="ac-link upper">{{ chs.getText("ac_egysegek") }}</span>
  </a>
  <a class="ac-block bordered padd" routerLink="/boats">
    <img src="assets/icons/boat.svg" />
    <span class="ac-link upper">{{ chs.getText("hajok") }}</span>
  </a>
  <a class="ac-block" routerLink="/account">
    <img src="assets/icons/account.svg" />
    <span class="ac-link upper">{{ chs.getText("felhasznalo_adatok") }}</span>
  </a>
</div>
