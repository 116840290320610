import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FAN_STEPS, MAIN_INFO } from 'src/app/core/models/configs';
import { Microcontroller } from 'src/app/core/models/microcontroller';
import { AdminControlService } from 'src/app/core/services/admin-control.service';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-admin-control',
  templateUrl: './admin-control.component.html',
  styleUrls: ['./admin-control.component.scss'],
})
export class AdminControlComponent implements OnInit {
  private controllerId: number;
  public controller: any;

  mainInfo = MAIN_INFO;
  fanSteps = FAN_STEPS;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    //public adminControlService: AdminControlService,
    public infoService: InfoService,
    public chs: CommonHandlerService
  ) {}

  ngOnInit() {
    this.controllerId = this.activatedRoute.snapshot.params['acId'];
    this.checkControllerAccess();
  }

  private checkControllerAccess() {
    this.adminService
      .getAdminController(this.controllerId)
      .subscribe((controller) => {
        console.log(controller);
        if (!controller) {
          this.router.navigateByUrl('/admin/overview');
        } else {
          this.controller = controller;
          this.infoService.initRefresh(
            this.controllerId,
            this.controller['mac'],
            this.controller['customer_id']
          );
          //this.connectToWS();
        }
      });
  }

  getFanSpeedRotate() {
    //Lehet, hogy nincs bekapcsolva a ventillátor
    /*
      Fan Auto Off: ha be van kapcsolva a klíma akkor megy
      Fan Auto On: ha ventilátor módban van és be van kapcsolva a klíma akkor megy, minden más módban ha megy a kompresszor akkor megy.
    */
    let fanOn = false;
    let animClass = 'rotate-';

    if (this.infoService.fanAutoOn) {
      if (this.infoService.mode == 3 && this.infoService.onoff == '1') {
        fanOn = true;
      } else if (
        this.infoService.mode != 3 &&
        this.infoService.info['comp'] == '1'
      ) {
        fanOn = true;
      }
    } else {
      if (this.infoService.onoff == '1') {
        fanOn = true;
      }
    }

    if (!fanOn) {
      return '';
    }

    if (this.infoService.info.fanSteps <= FAN_STEPS.TYPE1 + 1) {
      if (this.infoService.fan == 3) {
        return animClass + '5';
      } else if (this.infoService.fan == 2) {
        return animClass + '3';
      } else if (this.infoService.fan == 1) {
        return animClass + '1';
      } else if (this.infoService.fan == 4) {
        return animClass + '3';
      }
    } else {
      if (this.infoService.fan == 5) {
        return animClass + '5';
      } else if (this.infoService.fan == 4) {
        return animClass + '4';
      } else if (this.infoService.fan == 3) {
        return animClass + '3';
      } else if (this.infoService.fan == 2) {
        return animClass + '2';
      } else if (this.infoService.fan == 1) {
        return animClass + '1';
      } else if (this.infoService.fan == 6) {
        return animClass + '3';
      }
    }
  }

  toDirectMessage() {
    this.router.navigateByUrl('/admin/direct-message/' + this.controller.mac);
  }

  disconnectControllerFormUser() {
    if (confirm('Are you sure to disconnect the controller from the user?')) {
      this.adminService
        .adminDisconnectControllerFromUser(this.controllerId)
        .subscribe((response) => {
          if (!response.isSuccess()) {
            alert('Unable to disconnect controller');
          } else {
            this.controller.userId = null;
          }
        });
    }
  }
}
