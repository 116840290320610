import { Component, OnInit } from '@angular/core';
import { AC_TYPES } from 'src/app/core/models/configs';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';
import { InfoService } from 'src/app/core/services/info-service/info.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  public pageMode: string = 'L'; // L - list, D - detail

  public selectedError: any = null;

  private _ac: any = null;

  public get acType() {
    return this._ac?.service.type ?? AC_TYPES.AC;
  }

  constructor(
    public chs: CommonHandlerService,
    public infoService: InfoService
  ) {}

  ngOnInit(): void {
    if (this.infoService.localAcs.length > 0) {
      let ac = this.infoService.localAcs.find(
        (ac) => ac.id == this.infoService.ac
      );
      this._ac = ac;
      if (ac != undefined) {
        this.infoService.connectToWs(ac['mac']);
      }
    }
  }

  public showErrorDetails(error: any) {
    this.pageMode = 'D';
    this.selectedError = error;
  }

  public back() {
    this.pageMode = 'L';
  }
}
