export class ApiResponse {
  _success: boolean;
  _data: any[];
  _extra: any[];
  _errorCodes: any[];

  constructor(apiResultObj: any) {
    this._success = apiResultObj.success;
    if (apiResultObj.success) {
      this._data = apiResultObj.data;
      this._errorCodes = ['ok'];
      this._extra = apiResultObj.meta;
    } else {
      this._errorCodes =
        typeof apiResultObj.code == 'string'
          ? apiResultObj.code
          : apiResultObj.code;
      this._extra = apiResultObj.extra;
      this._data = apiResultObj;
    }
  }

  public isSuccess(): boolean {
    return this._success;
  }

  public get data() {
    return this._data;
  }

  public get extra() {
    return this._extra;
  }

  public get error() {
    return this._errorCodes;
  }
}
