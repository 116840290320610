import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as json_texts from '../../../../assets/lang_prd.json';
import { ApiResponse } from '../../models/api-response/api-response';

@Injectable({
  providedIn: 'root',
})
export class CommonHandlerService {
  lang: string;
  texts: object;

  constructor(private httpClient: HttpClient) {
    this.texts = (json_texts as any).default;
    let localLang = localStorage.getItem('mbc_serverLang');
    let tmp = localStorage.getItem('mbc_serverLang');
    if (tmp == 'en' || tmp == 'hu' || tmp == 'it') {
      localStorage.setItem('mbc_serverLang', 'EN');
    }
    if (localLang == undefined) {
      localStorage.setItem('mbc_serverLang', 'EN');
      localLang = 'EN';
    }
    this.lang = localLang;
  }

  loadTexts(url) {
    return this.httpClient
      .get(url)
      .pipe(
        mergeMap((res) => {
          this.texts = (res as ApiResponse).data;
          return of(this.texts);
        }),
      )
      .subscribe();
  }

  setLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('mbc_serverLang', lang);
  }

  public get actLang() {
    return this.lang;
  }

  public get langsRaw() {
    return Object.keys(this.texts);
  }

  getText(code: string, isError: boolean = false) {
    let type = isError ? 'errors' : 'texts';
    return this.texts[this.lang][type][code] || code;
  }

  getLangList() {
    let langList = [];
    let keys = Object.keys(this.texts);
    for (let i in keys) {
      langList.push({ value: keys[i], label: keys[i].toUpperCase() });
    }
    return langList;
  }

  handleError(formGroup: FormGroup, response: ApiResponse): string {
    let errors = response.error;

    if (
      errors[0] == 'letezo_email' ||
      errors[0] == 'nem_letezo_felhasznalo' ||
      errors[0] == 'nincs_megerositve' ||
      errors[0] == 'nem_letezo_mikrokontroller' ||
      errors[0] == 'nem_egyezo_jelszavak' ||
      errors[0] == 'nem_a_tied' ||
      errors[0] == 'technikai_hiba'
    ) {
      return this.getText(errors[0], true);
    }

    let inputs = Object.keys(errors);
    for (let i in inputs) {
      if (errors[inputs[i]]['Required'] != undefined) {
        formGroup
          .get(inputs[i])
          .setErrors({
            invalid: true,
            message: this.getText('err__kotelezo_mezo', true),
          });
      } else if (errors[inputs[i]]['Unique'] != undefined) {
        formGroup
          .get(inputs[i])
          .setErrors({
            invalid: true,
            message: this.getText('foglalt_email', true),
          });
      } else if (errors[inputs[i]]['Exists'] != undefined) {
        if (inputs[i] == 'email') {
          formGroup
            .get(inputs[i])
            .setErrors({
              invalid: true,
              message: this.getText('nem_regisztralt_email', true),
            });
        }
      } else if (errors[inputs[i]]['Email'] != undefined) {
        formGroup
          .get(inputs[i])
          .setErrors({
            invalid: true,
            message: this.getText('hibas_email_cim', true),
          });
      }
    }

    return null;
  }
}
