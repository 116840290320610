<div class="page-wrapper">
  <h2>{{ chs.getText("hajo_torlese") }}</h2>

  <form class="margin-top-50" [formGroup]="deleteForm">
    <div class="form-row">
      <label>{{ chs.getText("hajo_neve") }}:</label>
      <input
        type="text"
        formControlName="name"
        [ngClass]="{
          'is-invalid': submitted && deleteForm.controls.name.errors
        }"
      />
    </div>

    <div class="false-popup" *ngIf="confirm">
      <p class="center-content margin-top-30">
        {{ chs.getText("hajo_torles_megerosites") }}
      </p>

      <div class="space-content">
        <button class="margin-top-20 thin" (click)="confirmDel(true)">
          {{ chs.getText("torles") }}
        </button>
        <button class="margin-top-20 thin" (click)="confirmDel(false)">
          {{ chs.getText("megsem") }}
        </button>
      </div>
    </div>

    <div *ngIf="error != null" class="error-holder">
      {{ error }}
    </div>

    <div *ngIf="message != null" class="message-holder">
      {{ message }}
    </div>

    <div class="center-content margin-top-30" *ngIf="!confirm">
      <button class="margin-top-20 thin" (click)="initDel()">
        {{ chs.getText("torles") }}
      </button>
    </div>
  </form>

  <hr />

  <div class="bottom-holder">
    <div class="center-content">
      <a class="btn thin" routerLink="/boats">{{ chs.getText("vissza") }}</a>
    </div>
  </div>
  <img class="bg-img" src="assets/background/side_bg_2.svg" />
</div>
