import { ROLE } from './roles';

export class User {
  id: number;
  role: ROLE;
  first_name: string;
  last_name: string;
  mobile: string;
  email: string;
  token: string;

  public static createUser(data: any) {
    const user = new User();
    user.userData = data;
    return user;
  }

  set userData(data: any) {
    this.id = data.id;
    this.token = !!this.token ? this.token : data.token;
    this.role = data.role;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.email = data.email;
    this.mobile = data.mobile_number;
  }

  constructor(token?: string) {
    this.token = token;
  }

  get isOwner() {
    return this.role === ROLE.OWNER;
  }

  get isAdmin() {
    return this.role === ROLE.OWNER || this.role === ROLE.SUPERUSER;
  }

  get isDealer() {
    return this.role === ROLE.DEALER;
  }
}
