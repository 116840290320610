import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-num-selector',
  templateUrl: './num-selector.component.html',
  styleUrls: ['./num-selector.component.scss'],
})
export class NumSelectorComponent implements OnInit {
  @Input() value: number = 0;
  @Input() minVal: number = 0;
  @Input() maxVal: number = 100;
  @Input() orientation: string = 'horizontal';
  @Input() inactive: boolean = false;

  @Output() onChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  decrease() {
    if (!this.inactive) {
      this.value = this.value - 1 < this.minVal ? this.maxVal : this.value - 1;
      this.onChange.emit(this.value);
    }
  }

  increase() {
    if (!this.inactive) {
      this.value = this.value + 1 > this.maxVal ? this.minVal : this.value + 1;
      this.onChange.emit(this.value);
    }
  }
}
