import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/core/models/api-response/api-response';
import { ApiCallService } from 'src/app/core/services/api-call-service/api-call-service.service';
import { CommonHandlerService } from 'src/app/core/services/common-handler/common-handler.service';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss'],
})
export class ConfirmResetComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  error: string = null;
  message: string = null;
  token: string;

  constructor(
    public chs: CommonHandlerService,
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        },
      ],
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: 'blur',
        },
      ],
      passwordRe: [
        '',
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: 'blur',
        },
      ],
    });
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params.token;
  }

  hasError(input: string): boolean {
    return (
      this.resetPasswordForm.controls[input].invalid &&
      (this.resetPasswordForm.controls[input].dirty ||
        this.resetPasswordForm.controls[input].touched)
    );
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      let hasEmpty = false;
      Object.keys(this.resetPasswordForm.controls).forEach((key) => {
        this.resetPasswordForm.get(key).markAsDirty();
        if (this.resetPasswordForm.get(key).value.length < 1) {
          hasEmpty = true;
        }
      });
      if (hasEmpty) {
        this.error = this.chs.getText('kotelezo_mezok', true);
      }
      return;
    }

    const formdata = new FormData();
    formdata.append('email', this.resetPasswordForm.get('email').value);
    formdata.append('password', this.resetPasswordForm.get('password').value);
    formdata.append(
      'password_confirmation',
      this.resetPasswordForm.get('passwordRe').value,
    );
    formdata.append('token', this.token);
    this.apiCallService
      .apiPut<ApiResponse>('/api/forgotten-password', formdata)
      .subscribe((result) => {
        if (result.isSuccess()) {
          this.router.navigate(['success'], {
            state: { data: 'password-confirm' },
          });
        } else {
          this.error = this.chs.handleError(this.resetPasswordForm, result);
        }
      });
  }

  checkConfirm() {
    const pwd = this.resetPasswordForm.get('password').value;
    const confirm = this.resetPasswordForm.get('passwordRe').value;
    if (pwd.length > 0 && confirm.length > 0 && confirm != pwd) {
      this.resetPasswordForm.get('passwordRe').setErrors({ mismatch: true });
    }
  }
}
